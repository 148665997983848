import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  const links = [
    {
      text: 'IMPRESSUM',
      link: 'https://tennis-esports.com/impressum'
    },
    {
      text: 'LEGAL',
      link: 'https://tennis-esports.com/legal'
    },
    {
      text: 'PRIVACY',
      link: 'https://tennis-esports.com/privacy-policy'
    },
    {
      text: 'COMPANY',
      link: 'https://tennis-esports.com/company'
    },
    {
      text: 'CONTACTS',
      link: 'https://tennis-esports.com/contacts'
    },
  ]

  return (
    <footer className={styles.Footer}>
      <div className={styles.FooterContainer}>
        <p className={styles.FooterCopyright}>©2023 Tennis Esports. All rights reserved.</p>
        <div className={styles.FooterSocialWrapper}>
          <a href="https://www.instagram.com/tennisesports" target={"_blank"} className={styles.FooterSocial}>
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://youtube.com/channel/UCV13xYb3sNCZ5WUyNMHkX5g" target={"_blank"} className={styles.FooterSocial}>
            <i className="fa-brands fa-youtube"></i>
          </a>
          <a href="https://www.tiktok.com/@tennisesportsvr" target={"_blank"} className={styles.FooterSocial}>
            <i className="fa-brands fa-tiktok"></i>
          </a>
          <a href="https://www.linkedin.com/groups/12327035/" target={"_blank"} className={styles.FooterSocial}>
            <i className="fa-brands fa-linkedin-in"></i>
          </a>
          <a href="https://www.facebook.com/vrmotionlearning" target={"_blank"} className={styles.FooterSocial}>
            <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com/tennis_esports" target={"_blank"} className={styles.FooterSocial}>
            <i className="fa-brands fa-twitter"></i>
          </a>
        </div>
        <div className={styles.FooterLinks}>
          {links.map((l, index) => (
            <>
              <a className={styles.FooterLink} href={l.link}>{l.text}</a>
              {index !== links.length - 1 && window.innerWidth > 1200 && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
            </>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
