import React, {FC} from 'react';
import styles from "./Flag.module.css";
import defaultFlag from './assets/default.png';

type FlagProps = {
  countryIso2?: string
  useAsBg?: boolean;
};

const Flag: FC<FlagProps> = ({countryIso2, useAsBg}) => {
  let mainClass = styles.Flag
  let additionalStyle = {}

  if (useAsBg) {
    let flag = `url(${defaultFlag})`;
    if (countryIso2) {
      flag = `url(https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/flags/4x3/${countryIso2.toLowerCase()}.svg)`;
    }

    mainClass = styles.FlagBg
    additionalStyle = {
      background: `linear-gradient(156.59deg, rgba(255, 255, 255, 0) 17.07%, rgba(73, 91, 99, 0.5) 40.85%, #495B63 60.91%), ${flag}`
    }
  }


  let className = `fi ` + mainClass;
  if (countryIso2) {
    className = `fi fi-${countryIso2.toLowerCase()} ` + mainClass;
  } else if (!useAsBg) {
    additionalStyle = {
      backgroundImage: `url(${defaultFlag})`
    }
  }

  return <span className={className} style={additionalStyle}></span>
}

export default Flag;