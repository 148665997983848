export default [
	{
		path: '/login',
		component: 'LoginPage',
		title: 'Login',
	},
	{
		path: ['/register', '/register/player/:code?'],
		component: 'RegisterPage',
		title: 'Register',
	},
	{
		path: '/register/confirm-email/:token?',
		component: 'ConfirmEmailPage',
		title: 'Verify',
	},
	{
		path: '/forgot-password/:code',
		component: 'ForgotPasswordPage',
		title: 'Forgot Password',
	},
	{
		path: ['/', '/my'],
		component: 'MyPageV2',
		title: 'My VR Tennis',
		need_roles: ['ROLE_USER_PLAYER_UPDATE', 'ROLE_USER_PARTNER_UPDATE', 'ROLE_USER_EMPLOYEE_UPDATE'],
	},
	{
		path: '/public-profile/:token?',
		component: 'PublicProfile',
		title: 'My VR Tennis public profile',
	}
	/*
	{
		path: '/referral/:code',
		component: 'ReferralPage',
		title: 'Referral',
	},
	{
		path: '/products',
		component: 'ProductsPage',
		title: 'Products',
		menu: 'main',
	},
	{
		path: '/buy',
		component: 'BuyPage',
		title: 'Buy',
	},
	{
		path: ['/', '/my'],
		component: 'MyPage',
		title: 'My VR Tennis',
		need_roles: ['ROLE_USER_PLAYER_UPDATE', 'ROLE_USER_PARTNER_UPDATE', 'ROLE_USER_EMPLOYEE_UPDATE'],
	},
	{
		path: '/game/invitation/:code',
		component: 'MyPage',
		title: 'My VR Tennis',
		need_roles: ['ROLE_USER_PLAYER_GET_ONE', 'ROLE_USER_PARTNER_UPDATE', 'ROLE_USER_EMPLOYEE_UPDATE'],
	},
	{
		path: '/my/payment/:id',
		component: 'PaymentEdit',
		title: 'Edit Payment',
	},
	{
		path: '/my/product-instance/:id',
		component: 'ProductInstanceEdit',
		title: 'Edit Product Instance',
	},
	{
		path: '/ratings',
		component: 'RatingsPage',
		title: 'Ratings',
	},
	{
		path: '/tournaments',
		component: 'TournamentsPage',
		title: 'Tournaments',
	},
	{
		path: '/admin/player/:id',
		component: 'PlayerPage',
		title: 'View or edit player',
		need_roles: ['ROLE_USER_PLAYER_GET_ONE'],
	},
	{
		path: '/admin/partner/:id',
		component: 'PartnerPage',
		title: 'View or edit partner',
		need_roles: ['ROLE_USER_PARTNER_UPDATE'],
	},
	{
		path: '/admin/employee/:id',
		component: 'EmployeePage',
		title: 'View or edit employee',
		need_roles: ['ROLE_USER_EMPLOYEE_UPDATE'],
	},
	{
		path: '/payment/result/:id',
		component: 'PaymentProcessPage',
		title: 'PaymentProcess',
	},
	{
		path: ['/register', '/register/player/:code?', '/register/partner/:code?'],
		component: 'RegisterPage',
		title: 'Register',
	},
	*/
];
