import React, {FC} from 'react';

import styles from './PageTitle.module.css';

const PageTitle: FC = () => {
  return (
    <div className={styles.TitleWrapper}>
      <p className={styles.Title}>
        PLAYER PROFILES
      </p>
      <div className={styles.TitleUnderline} />
    </div>
  )
}

export default PageTitle;