import React, {createContext, useEffect, useState} from "react";
import axios, {AxiosResponse} from "axios";

export enum LangEnum {
    EN = 'EN',
    DE = 'DE',
    FR = 'FR',
    SP = 'SP',
}

export type TranslateItem = {
    ID: string;
    NAME: string;
    EN: string;
    DE: string;
    FR: string;
    SP: string;
}

export type TranslateObject = {
    [s in string]: {
        EN: string;
        DE: string;
        FR: string;
        SP: string;
    };
}

export type TranslateContextValue = {
    t: any;
    currentLang: LangEnum;
    setCurrentLang: (value: LangEnum) => void;
}

export const AVAILABLE_LANGS: LangEnum[] = [
    LangEnum.EN,
    LangEnum.DE,
    LangEnum.FR,
    LangEnum.SP,
]

export const TranslateContext = createContext<TranslateContextValue>({} as TranslateContextValue);

export const TranslateProvider: React.FC = ({ children }) => {
    const [currentLang, _setCurrentLang] = useState(LangEnum.EN);
    const [t, setT] = useState<TranslateObject>({});

    const setCurrentLang = (value: LangEnum) => { _setCurrentLang(value); };

    useEffect(() => {
        axios.get('https://portal.tennis-esports.com/public/web/text/content_text.json')
            .then((data: AxiosResponse<TranslateItem[]>) => {
                const tObj = data.data.map(item => ({
                    [item.NAME]: {
                        EN: item.EN,
                        DE: item.DE,
                        FR: item.FR,
                        SP: item.SP,
                    }
                })).reduce((acc, curr) => {
                    acc = { ...acc, ...curr };
                    return acc;
                }, {}) as TranslateObject;

                setT(tObj);
            });
    }, [])

    return <TranslateContext.Provider value={{
        t,
        currentLang,
        setCurrentLang,
    }}>
        {children}
    </TranslateContext.Provider>;
}
