import moment, {Moment} from 'moment-timezone';
import {getTokenData} from './security';

export class UserTime {
	static readonly inputFormat: string = 'YYYY-MM-DD HH:mm:ss';
	static readonly dateOutFormat: string = 'YYYY-MM-DD';
	static readonly hoursMinutesOutFormat: string = 'HH:mm [GMT]Z (z)';
	static readonly timeOutFormat: string = 'HH:mm:ss [GMT]Z (z)';
	static readonly dateTimePickerFormat: string = 'YYYY-MM-DD HH:mm [GMT]Z (z)';
	static readonly dateTimeOutFormat: string = 'YYYY-MM-DD HH:mm:ss [GMT]Z (z)';
	protected localMoment: Moment;

	constructor(utcDateTimeString: string) {
		this.localMoment = this.getLocalMoment(utcDateTimeString);
	}

	protected getLocalMoment(utcDateTimeString: string): Moment {
		return moment.utc(utcDateTimeString, UserTime.inputFormat).tz(new UserTimezone().getTimezoneName());
	}

	getMoment(): Moment {
		return this.localMoment.clone();
	}

	getLocalDate(): string {
		return this.localMoment.format(UserTime.dateOutFormat);
	}

	getLocalTime(): string {
		return this.localMoment.format(UserTime.timeOutFormat);
	}

	getLocalDateTime(): string {
		return this.localMoment.format(UserTime.dateTimeOutFormat);
	}
}

export class UserTimezone {
	protected forceReguess: boolean;

	constructor(forceReguess: boolean = false) {
		this.forceReguess = forceReguess;
	}

	protected static getUserTimezoneName(): string {
		const data = getTokenData();

		if (!data?.timezone) {
			throw new Error('Data is empty or has no timezone');
		}

		return data.timezone.name;
	}

	protected getBrowserTimezoneName(): string {
		return moment.tz.guess(this.forceReguess);
	}

	getTimezoneName(): string {
		try {
			return UserTimezone.getUserTimezoneName();
		} catch (e: any) {
			return this.getBrowserTimezoneName();
		}
	}

	setAsMomentsDefault(): void {
		moment.tz.setDefault(this.getTimezoneName());
	}
}
