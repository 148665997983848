import React, {FC, useState} from 'react';
import styles from './HeaderBlock.module.css';
import Button from '../../Shared/Button/Button';
import PlayVideo from '../PlayVideo/PlayVideo';

const HeaderBlock: FC = () => {
	const [isVideoVisible, setIsVideoVisible] = useState<boolean>(false);

	const openVideo = () => setIsVideoVisible(true);
	const closeVideo = () => setIsVideoVisible(false);

	return (
		<div className={styles.Container}>
			{isVideoVisible && <PlayVideo onClose={closeVideo} />}
			<div className={styles.VideoContainer}>
				<img
					className={styles.VideoPreview}
					src={'/design/video_placeholder_1.png'}
					alt={'video placeholder'}
				/>
				<img
					className={styles.OpenVideo}
					onClick={openVideo}
					src={'/design/icon_play_video.png'}
					alt={'open video'}
				/>
			</div>
			<p className={styles.Text}>TENNIS IN VIRTUAL REALITY IS HERE</p>
			<div className={styles.BottomContainer}>
				<Button href={'https://discord.gg/8rcSArKJD4'}>Join the chat</Button>
				<p className={styles.AvailableText}>available for</p>
				<img className={styles.OculusIcon} src={'/design/icon_oculus_1.png'} alt={'oculus icon'} />
			</div>
		</div>
	);
};

export default HeaderBlock;
