import characterDefault from "./assets/profile_character_default.png";
import female from './assets/profile_character_female.png';
import male from './assets/profile_character_male.png';

export enum HomeAvatar {
    default = 'default',
    male = 'male',
    female = 'female',
}

export const homeAvatar: { [s in HomeAvatar]: string } = {
    [HomeAvatar.default]: characterDefault,
    [HomeAvatar.male]: male,
    [HomeAvatar.female]: female,
}
