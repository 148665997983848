import defaultPlayerAvatar from './defaultPlayerAvatar.png';
import defaultPartnerAvatar from './defaultPartnerAvatar.png';
import {isPlayer, isVPlayer} from '../security';

export const getAvatar = (url?: string | null, ...additionalFunctions: ((url: string) => string)[]): string => {
	switch (url) {
		case null:
			return defaultPlayerAvatar;

		case undefined:
			return '';

		default:
			return additionalFunctions.reduce((acc, f) => f(acc), url);
	}
};

export const getPartnerAvatar = (url?: string | null, ...additionalFunctions: ((url: string) => string)[]): string => {
	switch (url) {
		case null:
			return defaultPartnerAvatar;

		case undefined:
			return '';

		default:
			return additionalFunctions.reduce((acc, f) => f(acc), url);
	}
};

export const getMyAvatar = (url?: string | null, ...additionalFunctions: ((url: string) => string)[]): string => {
	switch (url) {
		case null:
			return isPlayer() || isVPlayer() ? defaultPlayerAvatar : defaultPartnerAvatar;

		case undefined:
			return '';

		default:
			return additionalFunctions.reduce((acc, f) => f(acc), url);
	}
};
