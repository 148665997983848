import React, {FC} from 'react';
import {Layout} from 'antd';
import {isAuthenticated} from '../../utils/security';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

const {Content} = Layout;

const EsportLayout:FC = ({children}) => {
	return (
		<Layout className={'es-layout ' + (isAuthenticated() ? 'esp-user-logged-in' : 'esp-user-logged-out')}>
			<Header />
			<Content className="content">{children}</Content>
			<Footer />
		</Layout>
	);
};

export default EsportLayout;
