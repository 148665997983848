import React, {FC, useState} from 'react';
import {Button, Form, Input, message, Modal} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {Store} from 'rc-field-form/lib/interface';
import axios from '../../../axios-api';
import {AxiosError} from 'axios';
import {RouteComponentProps} from 'react-router';

type EmailVerificationProps = Pick<RouteComponentProps, 'history'>;

const EmailVerification: FC<EmailVerificationProps> = ({history}) => {
	const [form] = useForm();

	const [showModal, setShowModal] = useState(false);

	const onFinish = (value: Store) => {
		axios
			.post(`/auth/resend-confirmation-email`, {email: value.email})
			.then((result) => {
				history.replace('/register/confirm-email');
				setShowModal(false);
			})
			.catch((error: AxiosError) => {
				if ((error.response?.status === 400 || error.response?.status === 404) && error.response?.data) {
					message.error(error.response?.data.message);
				} else {
					message.error(error.message);
				}
			});
	};

	return (
		<>
			<Button
				className="es-link-btn-no-auth width-100-mobile"
				type="primary"
				key="resend"
				style={{width: '100%'}}
				onClick={() => setShowModal(true)}
			>
				Resend email verification
			</Button>
			<Modal visible={showModal} footer={false} onCancel={() => setShowModal(false)}>
				<div style={{margin: '0 20px'}}>
					<Form form={form} onFinish={onFinish}>
						<Form.Item
							name="email"
							label="Enter email"
							rules={[
								{
									required: true,
									message: 'Please input email!',
								},
								{
									type: 'email',
									message: 'The input is not valid E-mail!',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Button type={'primary'} htmlType="submit">
							Send
						</Button>
					</Form>
				</div>
			</Modal>
		</>
	);
};

export default EmailVerification;
