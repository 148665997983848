import React, {useContext} from 'react';
import styles from './Header.module.css';
import Button from "../../Shared/Button/Button";
import {ReactComponent as Profile} from "../assets/profile.svg";
import {ReactComponent as SignIn} from "../assets/sign-in.svg";
import {logout} from "../../../utils/security";
import {AVAILABLE_LANGS, LangEnum, TranslateContext} from "../../Providers/TranslateProvider";

const Header = () => {
  const { setCurrentLang } = useContext(TranslateContext);

  return (
    <header className={styles.HeaderContainer}>
      <div className={styles.ContentContainer}>
        <div className={styles.Wrapper}>
          <div className={styles.FirstRow}>
            <a href="https://tennis-esports.com/" className={styles.HeaderLogoLink}>
              <img src={"/design/logo_esports_3.png"} alt={"esport main page"} />
            </a>
            <div className={styles.NavigationLinks}>
              <a href={"https://tennis-esports.com/cup"} className={styles.NavigationLink}>INSTANT CUP</a>
              <a href={"https://tennis-esports.com/leaderboard"} className={styles.NavigationLink}>LEADERBOARDS</a>
            </div>
          </div>
          <div className={styles.SecondRow}>
            <select className={styles.LangSelect} onChange={(e) => setCurrentLang(e.target.value as LangEnum)}>
              {AVAILABLE_LANGS.map((item: string) => (<option key={item} value={item}>{item}</option>))}
            </select>
            <div className={styles.HeaderSignIn}>
              {<a href={"/my?tab=MyAccount"} className={styles.ProfileLink}><Profile /></a>}
              {<SignIn onClick={() => logout()}/>}
            </div>
            <Button href={"https://www.oculus.com/experiences/quest/4872542182873415"}>Download app</Button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
