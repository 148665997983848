import React, {FC} from 'react';
import {RouteComponentProps} from 'react-router';
import styles from './RegisterPage.module.css';

import UserHeaderSection from '../../Components/UserHeaderSection/UserHeaderSection';
import RegistrationForm from '../../Forms/RegistrationForm/RegistrationForm';
import PageTitle from "../../Components/PageTitle/PageTitle";

type RegisterPageProps = RouteComponentProps<{
	code?: string;
}>;

const RegisterPage: FC<RegisterPageProps> = (props: RegisterPageProps) => {
	const {
		match: {
			params: {code},
		},
	} = props;

	return (
		<UserHeaderSection>
			<PageTitle />
			<div className={styles.Container}>
				<RegistrationForm {...props} code={code} />
			</div>
		</UserHeaderSection>
	);
};

export default RegisterPage;
