import React from 'react';
import {Layout} from 'antd';
import {NavLink} from 'react-router-dom';
import styles from './EsportFooter.module.css';
import FooterLogoImg from './logo-vrml-bw-600.png';
import { FacebookOutlined, LinkedinOutlined } from '@ant-design/icons';
import config from '../../../config';

const {Footer} = Layout;

const EsportFooter = () => {

	const getCMSHostName = () => {
		return config.envVars.cmsHost;
	};
	const cmsHostName = getCMSHostName();
  
  const menuArr = [
    {title:'IMPRESSUM', path:cmsHostName + '/impressum'},
    {title:'LEGAL', path:cmsHostName + '/legal'},
    {title:'PRIVACY POLICY', path:cmsHostName + '/privacy-policy'},
    {title:'COMPANY', path:cmsHostName + '/company'},
    {title:'CONTACTS', path:cmsHostName + '/contacts'},
  ];
  
	return (
		<Footer className={"footer es-container " + styles.Footer}>
      <div className={styles.Container}>
        <a className={styles.FooterLogoLink + " es-link-noul"} href="https://vr-motion-learning.com/">
          <img className={styles.FooterLogo} src={FooterLogoImg} />
        </a>
        <div className={styles.FooterCopyright}>VR Motion Learning GmbH & Co KG</div>
        <div className={styles.FooterNav}>
          {menuArr.map((l, index) => <a className={styles.Link} href={l.path} key={"MenuFooter" + l.title.replace(' ', '-')}>{l.title}</a>)}
        </div>
        <ul className={styles.FooterSocialList}>
          <li><a className="es-link-noul" href="https://www.linkedin.com/company/vrtennistrainer/" target="_blank" rel="noreferrer"><LinkedinOutlined className="es-ant-icon" /></a></li>
          <li><a className="es-link-noul" href="https://www.facebook.com/vrmotionlearning/" target="_blank" rel="noreferrer"><FacebookOutlined className="es-ant-icon" /></a></li>
        </ul>
      </div>
        
      <div className={styles.GreenDiv} />
		</Footer>
	);
};

export default EsportFooter;
