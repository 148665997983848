import React, {FC} from 'react';
import styles from './CommonFooterSection.module.css';

type CommonFooterSectionProps = {};
const CommonFooterSection: FC<CommonFooterSectionProps> = ({children}) => {
	return (
		<section className={styles.Background}>
			<div>{children}</div>
		</section>
	);
};

export default CommonFooterSection;
