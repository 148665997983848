import React, {FC, useEffect, useState} from 'react';
import styles from './PlayVideo.module.css';

type PlayVideoProps = {
	onClose: () => void;
};
const PlayVideo: FC<PlayVideoProps> = ({onClose}) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
		});
	}, []);

	return (
		<div className={styles.Overlay} onClick={onClose}>
			<div className={styles.VideoWrapper}>
				<img className={styles.CloseIcon} src={'/design/close.png'} alt={'close icon'} />
				<iframe
					width={windowWidth < 1200 ? '325px' : '1100px'}
					height={windowWidth < 1200 ? '190px' : '620px'}
					src="https://www.youtube.com/embed/aCzvA-YYXLA?autoplay=1&modestbranding=1&autohide=1&showinfo=0"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</div>
		</div>
	);
};

export default PlayVideo;
