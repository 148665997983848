import React, {FC} from 'react';
import styles from './SocialMedia.module.css';
import {PublicProfileSettings, SocialLink} from "../../../common/Entities/types";
import config from "../../../config";
import te from './assets/te.png';

type SocialMediaType = {
  currentLinks: SocialLink[];
  publicProfileSettings: PublicProfileSettings | undefined;
};

const SocialMedia: FC<SocialMediaType> = ({currentLinks, publicProfileSettings}) => {
  //console.log("[SocialMedia]", currentLinks);

  /*
  todo
  if (currentLinks.length === 0 && publicProfileSettings?.token) {
    return (
      <div className={styles.SocialWrapper}>
        <a href={`${config.envVars.baseHost}/public-profile/${publicProfileSettings?.token}`} target={"_blank"} className={styles.Social}>
          <img className={styles.TennisEsport} src={te} alt={'tennis esport'} />
        </a>
      </div>
    )
  }
  */

  //todo
  //instagram => fa-instagram
  //youtube => fa-youtube
  //facebook => fa-facebook-f
  //linkedin = fa-linkedin-in

  return (
    <div className={styles.SocialWrapper}>
      <a href="https://www.instagram.com/tennisesports" target={"_blank"} className={styles.Social}>
        <i className="fa-brands fa-instagram"></i>
      </a>
      <a href="https://www.youtube.com/channel/UCV13xYb3sNCZ5WUyNMHkX5g" target={"_blank"} className={styles.Social}>
        <i className="fa-brands fa-youtube"></i>
      </a>
      <a href="https://www.linkedin.com/groups/12327035/" target={"_blank"} className={styles.Social}>
        <i className="fa-brands fa-linkedin-in"></i>
      </a>
      <a href="https://www.facebook.com/vrmotionlearning" target={"_blank"} className={styles.Social}>
        <i className="fa-brands fa-facebook-f"></i>
      </a>
    </div>
  )
}

export default SocialMedia;