import React from 'react';
import {Switch} from 'react-router-dom';
import {ProtectedRoute} from '../components/ProtectedRoute/ProtectedRoute';
import EsportLayout from './EsportLayout/EsportLayout';
import config from '../config';

const Esport = () => {
	const routes = config.routes;

	const protectedRoutes = routes.map((route) => {
		return (
			<ProtectedRoute
				key={Array.isArray(route.path) ? route.path.join() : route.path}
				path={route.path}
				renderedComponent={route.component}
				needRoles={route.need_roles ?? undefined}
			/>
		);
	});

	return (
		<Switch>
			<EsportLayout>{protectedRoutes}</EsportLayout>
		</Switch>
	);
};

export default Esport;
