import React, {FC} from 'react';
import styles from './Settings.module.css';
import {
	Country,
	DictionaryItem,
	Player,
	PublicProfileBasicData, PublicProfileSettings, SocialLink,
} from '../../../../common/Entities/types';
import EditPlayerForm from '../../../Forms/EditPlayerForm/EditPlayerForm';
import {HomeAvatar, homeAvatar} from "../consts";
import {chooseAvatar} from "../MyPage";

type Settings = {
	player: Player;
	publicProfileSettings: PublicProfileSettings | undefined;
	basicProfileData: PublicProfileBasicData | null;
	currentSocialLinks: SocialLink[];
	countryDic: Country[];
	timezoneDic: DictionaryItem[];
	handTypeDic: DictionaryItem[];
	playTypeDic: DictionaryItem[];
	bodyTypeDic: DictionaryItem[];
	homeAvatarDic: DictionaryItem[];
	socialMediaTypeDic: DictionaryItem[];
};

const Settings: FC<Settings> = ({
	player,
	publicProfileSettings,
	countryDic,
	timezoneDic,
	handTypeDic,
	playTypeDic,
	bodyTypeDic,
	homeAvatarDic,
	socialMediaTypeDic,
	basicProfileData,
	currentSocialLinks,
}) => {
	const [avatar, setAvatar] = React.useState<HomeAvatar>(chooseAvatar(basicProfileData?.home_avatar, basicProfileData?.gender) as HomeAvatar || HomeAvatar.default);

	const onGenderChange = (value) => {
		if (value === 1) {
			setAvatar(HomeAvatar.male);
		} else if (value === 2) {
			setAvatar(HomeAvatar.female);
		}
	}

	const goToNext = () => {
		switch (avatar) {
			case HomeAvatar.default:
				setAvatar(HomeAvatar.male);
				break;
			case HomeAvatar.male:
				setAvatar(HomeAvatar.female);
				break;
			case HomeAvatar.female:
				setAvatar(HomeAvatar.male);
				break;
		}
	}

	const goToPrev = () => {
		switch (avatar) {
			case HomeAvatar.default:
				setAvatar(HomeAvatar.female);
				break;
			case HomeAvatar.female:
				setAvatar(HomeAvatar.male);
				break;
			case HomeAvatar.male:
				setAvatar(HomeAvatar.default);
				break;
		}
	}

	return (
		<div className={styles.GradientCard}>
			<h3 className={styles.CartTitle}>PLAYER PROFILE SETTINGS</h3>
			<div className={styles.CardWrapper}>
				<div className={styles.CarouselColumn}>
					<img src={homeAvatar[avatar]} className={styles.CarouselImg} alt={'playerCarouselImg'} />
					{
					<p className={styles.CarouselNavigation}>
						<span className={styles.Left} onClick={goToNext}>&lt;&lt;</span>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<span className={styles.Right} onClick={goToPrev}>&gt;&gt;</span>
					</p>
					}
				</div>
				<div className={styles.ProfileColumn}>
					<EditPlayerForm
						player={player}
						currentSocialLinks={currentSocialLinks}
						publicProfileSettings={publicProfileSettings}
						homeAvatar={avatar}
						homeAvatarDic={homeAvatarDic}
						countryDic={countryDic}
						timezoneDic={timezoneDic}
						handTypeDic={handTypeDic}
						playTypeDic={playTypeDic}
						bodyTypeDic={bodyTypeDic}
						socialMediaTypeDic={socialMediaTypeDic}
						onGenderChange={onGenderChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default Settings;
