export const getQueryVariable = (variable, search): string => {
	const query = search.substring(1);
	const vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) == variable) {
			return decodeURIComponent(pair[1]);
		}
	}
	return '';
};

export const getReturnURL = (search: string): string => {
	return getQueryVariable('return', search);
};
