// used on development env
const config = {
	env: 'dev',
	//baseApiUrl: 'http://localhost:81',
	baseApiUrl: 'https://dev.tennis-esports.com/api',
	baseHost: 'https://dev.tennis-esports.com',
	cmsHost: 'https://preprod.tennis-esports.com',
};

export default config;
