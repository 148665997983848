class NotificationService {
	forceUpdates: any[] = [];
	count: number = 0;

	getNotificationCount() {
		return this.count;
	}

	registerForceUpdate(callback) {
		this.forceUpdates.push(callback);
	}

	setNotificationCount(count: number) {
		this.count = count;
	}

    update() {
        this.forceUpdates.forEach((forceUpdate) => forceUpdate());
    }

	removeOneNotification() {
		if (this.count) {
			--this.count;
		}
		this.forceUpdates.forEach((forceUpdate) => forceUpdate());
	}
}

const notificationService = new NotificationService();
export default notificationService;
