import React, {FC, useState} from 'react';
import {Button, Form, Input, message, Modal} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {Store} from 'rc-field-form/lib/interface';
import axios from '../../../axios-api';

type PasswordRecoveryProps = {
	email?: string;
};

const PasswordRecovery: FC<PasswordRecoveryProps> = ({email}) => {
	const [form] = useForm();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);

	const onFinish = (value: Store) => {
		setLoading(true);
		try {
			axios
				.post(`/auth/forgot-password`, {
					email: value.email,
				})
				.then(() => {
					closeModal();
				})
				.catch((err) => {
					if (err.response.data && err.response.data.message) {
						message.error(err.response.data.message);
					}
				})
				.finally(() => setLoading(false));
		} catch (e: any) {
			setLoading(false);
			message.error(e.response?.data.message);
			console.log(e);
		}
	};

	const closeModal = () => {
		setShowModal(false);
		form.resetFields();
	};

	return (
		<>
			<Button
				className="es-link-btn-no-auth es-link-btn-large width-100-mobile"
				type="primary"
				key="recovery"
				onClick={() => setShowModal(true)}
			>
				Recover password
			</Button>
			<Modal destroyOnClose title="Recover password" visible={showModal} footer={false} onCancel={closeModal}>
				<p>Please specify your user Email address to receive password recovery link</p>
				<Form form={form} onFinish={onFinish} initialValues={{email: email}}>
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								message: 'Please input email!',
							},
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
						]}
					>
						<Input placeholder="User Email" />
					</Form.Item>
					<Button
						className={'es-link-btn-no-auth es-link-btn-large es-link-btn-center width-100-mobile'}
						type={'primary'}
						htmlType="submit"
						loading={loading}
					>
						Send
					</Button>
				</Form>
			</Modal>
		</>
	);
};

export default PasswordRecovery;
