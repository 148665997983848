import React, {useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {History} from 'history';
import {Form, Input, Button, message} from 'antd';
import {Store, ValidateErrorEntity} from 'rc-field-form/lib/interface';
import {AxiosError} from 'axios';
import axios from '../../../axios-api';
import {setToken} from '../../../utils/security';
import {UserTimezone} from '../../../utils/userTime';
import {getReturnURL} from '../../../common/tools/query';
import EmailVerification from '../../Modals/EmailVerification/EmailVerification';
import PasswordRecovery from '../../Modals/PasswordRecovery/PasswordRecovery';
import styles from './LoginForm.module.css';

type LoginFormProps = RouteComponentProps<any, any, any> & {
	token?: string;
};

const LoginForm = ({history, location, token}: LoginFormProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [userName, setUserName] = useState<string>();

	const onFinish = (values: Store) => {
		if (token) {
			axios
				.patch(`/auth/register/confirm-email/${token}`, {
					email: values.username,
					password: values.password,
					re_password: values.password,
				})
				.then((response) => {
					login(values.username, values.password, history);
				})
				.catch((error: AxiosError) => {
					if (error.response?.status === 400 && error.response?.data.code === 'NOT_VALID_TOKEN') {
						message.error(
							'Hi! Your login email confirmation link is unfortunately expired. In order to confirm it please press the button “Resend email verification”',
							4,
						);
					}
				});
		} else {
			login(values.username, values.password, history);
		}
	};

	const login = (username: string, password: string, history: History) => {
		setLoading(true);
		axios
			.post('/auth/login', {u: username, p: password})
			.then((r) => {
				setLoading(false);
				setToken(r.data.token, r.data.refresh_token);
				let ret = getReturnURL(location.search);
				new UserTimezone().setAsMomentsDefault();

				if (ret !== '') {
					history.push(ret);
				} else {
					history.push(location.state?.from?.pathname || '/my?tab=MyAccount');
				}
			})
			.catch((error: AxiosError) => {
				if (error.response?.status === 400 && error.response?.data) {
					message.error(error.response?.data.message);
				} else {
					message.error('Wrong email or password');
				}
				setLoading(false);
			});
	};

	const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
		console.log('Failed:', errorInfo);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserName(e.target.value);
	};

	const handleRegisterClick = () => {
		history.push('/register');
	};

	return (
		<div className={styles.Container}>
			{token && (
				<p className={styles.LoginText}>
					Your Email address verification is successfull!
					<br /> Please login to fill-in registration form
					<br /> and start using Tennis Esports
				</p>
			)}
			<Form
				className="esp-register-form"
				name="login"
				initialValues={{remember: true}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item
					className="esp-register-input"
					name="username"
					rules={[{required: true, message: 'Please input your username!'}]}
				>
					<Input value={userName} onChange={onChange} placeholder={'Username'} />
				</Form.Item>

				<Form.Item
					className="esp-register-input"
					name="password"
					rules={[{required: true, message: 'Please input your password!'}]}
				>
					<Input.Password placeholder={'Password'} />
				</Form.Item>

				<div className={styles.ButtonsWrapper}>
					<div className={styles.LoginButtonWrapper}>
						<Form.Item className="esp-register-button">
							<Button
								className="es-link-btn-no-auth width-100-mobile"
								type="primary"
								htmlType="submit"
								loading={loading}
							>
								Login
							</Button>
						</Form.Item>
					</div>

					<div className={styles.LoginButtonWrapper}>
						<Form.Item className="esp-register-button">
							<Button
								className="es-link-btn-no-auth es-link-btn-large width-100-mobile"
								type="primary"
								onClick={handleRegisterClick}
							>
								Registration
							</Button>
						</Form.Item>
					</div>

					<Form.Item className="esp-register-button">
						<PasswordRecovery email={userName} />
					</Form.Item>

					<Form.Item className="esp-register-button">
						<EmailVerification history={history} />
					</Form.Item>
				</div>
			</Form>
		</div>
	);
};

export default LoginForm;
