import axios from '../../../../axios-api';
import {AxiosResponse} from 'axios';
import {
	BodyType,
	Club,
	Country,
	DictionaryItem,
	Employee,
	EmployeePosition,
	GameStatus,
	GameType,
	HandType,
	League,
	OwnProduct,
	OwnProducts,
	Partner,
	Player,
	Product,
	ProductType,
	ProductInstanceStatus,
	ReferralStatus,
	Timezone,
	UserStatus,
	UserType,
	Setting,
	PlayersInClubFilterValues,
	PlayersInClubRequestParams,
	PlayersReceivedResponse,
	ProductsReceivedResponse,
	OwnProductsRequestParams,
	OwnProductsFilterValues,
	LeagueStatus,
	LeagueType,
	ProductDictionaryItem, PlayType,
} from '../../../../common/Entities/types';
import {PaginationModel} from '../../../../utils/helpers';
import { getAccountTypeId } from '../../../../utils/security';
import { DropdownItem } from '../../../ExcelTable/NewExcelTable';

// USERS
export const loadUsers = (filters?: URLSearchParams) => axios.get('/user?' + filters?.toString());
export const loadPlayer = (player_id: number): Promise<Player> =>
	axios.get(`/user/player/${player_id}`).then((response) => response.data);
export const loadPlayers = (): Promise<Player[]> => axios.get(`/user/player`).then((response) => response.data.data);
export const loadEmployee = (employee_id: number): Promise<Employee> =>
	axios.get(`/user/employee/${employee_id}`).then((response) => response.data);
export const loadPartner = (partner_id: number): Promise<Partner> =>
	axios.get(`/user/partner/${partner_id}`).then((response) => response.data);
export const loadPartners = (): Promise<Partner[]> => axios.get(`/user/partner`).then((response) => response.data.data);
export const loadAllPartners = (): Promise<Partner[]> => axios.get(`/user/partner?limit=10000`).then((response) => response.data.data);
export const loadFriends = (): Promise<Player[]> =>
	axios.get(`/user/player/friend`).then((response: AxiosResponse<{data: Player[]}>) => response.data.data);
export const loadClubId = () => {
	return axios.get(`/user/partner/${getAccountTypeId()}/short`).then((response) => response.data);
};	
export const loadPlayersInClub = (
	clubId: number,
	filters?: PlayersInClubFilterValues,
	pagination?: PaginationModel,
	isVirtual?: boolean,
): Promise<AxiosResponse<PlayersReceivedResponse>> => {
	let params: PlayersInClubRequestParams = {};

	if (filters?.statusId) {
		params.status_id = filters.statusId;
	}

	if (filters?.search) {
		params.search = filters.search;
	}

	if (filters?.nickname) {
		params.nickname = filters.nickname;
	}

	if (filters?.product) {
		params.product_id = filters.product;
	}

	if (pagination?.limit) {
		params.limit = pagination.limit;
	}

	if (pagination?.offset) {
		params.offset = pagination.offset;
	}

	// todo
	params.player_type_ids = [1];

	if (isVirtual) {
		params.player_type_ids = [3];
	}

	return axios.get(`/club/${clubId}/player?sort=DESC`, {
		params: params,
	});
};
// todo need refactor
export const loadClubPlayers = (clubId: number): Promise<Player[]> =>
	axios
		.get(`/club/${clubId}/player?&limit=1000`)
		.then((response: AxiosResponse<{data: Player[]}>) => response.data.data);

// DICTIONARY
export const loadBodyType = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/body-type')
		.then((items: AxiosResponse<BodyType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadHomeAvatar = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/home-avatar')
		.then((items: AxiosResponse<BodyType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadHandType = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/hand-type')
		.then((items: AxiosResponse<HandType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadPlayType = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/play-type')
		.then((items: AxiosResponse<PlayType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));

export const loadSocialMediaType = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/social-media-type')
		.then((items: AxiosResponse<PlayType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));

export const loadEmployeePositions = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/employee-position')
		.then((items: AxiosResponse<EmployeePosition[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadCountry = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/country')
		.then((items: AxiosResponse<Country[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadAllCountryData = (): Promise<Country[]> =>
	axios
		.get('/dictionary/country')
		.then((items: AxiosResponse<Country[]>) => items.data);	
export const loadGameTypes = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/game-type')
		.then((items: AxiosResponse<GameType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadSettingType = (): Promise<DropdownItem[]> =>
	axios
		.get('/dictionary/setting-type')
		.then((items: AxiosResponse<GameType[]>) => items.data.map((i) => ({name: i.value, id: i.id})));
export const loadGameStatuses = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/game-status')
		.then((items: AxiosResponse<GameStatus[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadTimezone = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/timezone')
		.then((items: AxiosResponse<Timezone[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadReferralStatus = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/referral-status')
		.then((items: AxiosResponse<ReferralStatus[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadUserType = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/user-type')
		.then((items: AxiosResponse<UserType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadUserStatus = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/user-status')
		.then((items: AxiosResponse<UserStatus[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadProductInstanceStatus = (): Promise<DictionaryItem[]> =>
	axios
		.get(`/dictionary/product-instance-status`)
		.then((items: AxiosResponse<ProductInstanceStatus[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadPaymentStatuses = (): Promise<DictionaryItem[]> =>
	axios
		.get(`/dictionary/payment-status`)
		.then((items: AxiosResponse<ProductInstanceStatus[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadAvailablePlayerProducts = (): Promise<AxiosResponse<ProductsReceivedResponse>> =>
	axios.get<ProductsReceivedResponse>(`/product/available/player/dictionary`);

// OTHER
export const loadProductType = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/product-type')
		.then((items: AxiosResponse<ProductType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadClubs = (): Promise<DictionaryItem[]> =>
	axios.get('/club').then((items: AxiosResponse<Club[]>) => items.data.map((i) => ({name: i.name, value: i.id, is_default: i.is_default})));
export const loadLeagues = (): Promise<DictionaryItem[]> =>
	axios.get('/league').then((items: AxiosResponse<League[]>) => items.data.map((i) => ({name: i.name, value: i.id, is_default: i.is_default})));
export const loadProducts = (): Promise<ProductDictionaryItem[]> =>
	axios
		.get(`/product`)
		.then((items: AxiosResponse<Product[]>) => items.data.map((i) => ({name: i.name, value: i.id, name_commercial: i.name_commercial})));
export const loadSettings = (): Promise<Setting[]> =>
	axios
		.get('/dictionary/setting')
		.then((items: AxiosResponse<Setting[]>) => items.data.map((i) => ({name: i.name, code: i.code, value_numeric: i.value_numeric})));
export const loadOwnProducts = <T extends OwnProduct = OwnProduct>(
	filters: OwnProductsFilterValues,
	pagination: PaginationModel = {} as any,
): Promise<OwnProducts<T>> => {
	let params: OwnProductsRequestParams = {};

	if (filters.product_instance_status_id) {
		params.product_instance_status_id = filters.product_instance_status_id;
	}

	if (pagination?.limit) {
		params.limit = pagination.limit;
	}

	if (pagination?.offset) {
		params.offset = pagination.offset;
	}

	return axios
		.get(`/product/instance/own?sort=DESC`, {
			params: params,
		})
		.then((response) => response.data);
};

export const loadLeagueStatus = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/league-status')
		.then((items: AxiosResponse<LeagueStatus[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadLeagueType = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/league-type')
		.then((items: AxiosResponse<LeagueType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));		
export const loadEventType = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/event-type')
		.then((items: AxiosResponse<UserType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));
export const loadProcess = (): Promise<DictionaryItem[]> =>
	axios
		.get('/dictionary/process')
		.then((items: AxiosResponse<UserType[]>) => items.data.map((i) => ({name: i.value, value: i.id, is_default: i.is_default})));