import React from 'react';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import {Menu} from 'antd';
import config from '../../../config';

const MainMenu = (): JSX.Element => {
  const selectedKeys = [];
  const menuArr = [
    {title:'Ratings', path:'/ratings', key:'RatingsMainMenu', cls:''},
    {title:'Tournaments', path:'/tournaments', key:'TournamentsMainMenu', cls:''},
  ];

	return (
    <>
  {/*
      {menuArr.map((item, index) => <a href={item.path} key={item.key} className={item.cls}>{item.title}</a>)}
  */}
    </>
    
	);
};

export default withRouter(MainMenu);
