import jsonwebtoken from 'jsonwebtoken';
import axios from '../axios-api';
import appConfig from '../config';

const jwtPublicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqLKLQTIvCo0KPQsRB59H
rkXcYCj1cuc6cBNSwduFCuWAZKPMZkdevW+7a8pK1edRtpEGH+v7lnM8yiiAVxRw
9om07LeYT3GqrU4WEG9bqYyEmg9gj5UQmI+KzKwRP9swAV2H3lD0iyc4JDLQKDiV
LoiKS+d2pz/Zy3IkNod9T+BaGzTVaPdkb9OCNYHjZxh9fHeq7M9/VX+BA4iw1U1v
JhGeyPtS9HJJRJ0n3Ka/PupNZHbq1dxyOUFWYDHyk/UwrfumEIoKKuGrKLtQtf72
7is84Swmq3JqtMz1TvPOB/Llfhi8txR6AHYrzLex53lP5d+XXmFVzX88bC1GIvVX
wOSkfEvDi+E8XeYH8jg7iIT+HuN06WblPIRlqTbnIRDpzt6TuqGzXBVwKZxuw6bp
DPUnrPqanXdtpgWAhbWB6xwGh2I1gIwTJ1lwFS7oO3HRNExIkLbm0gi5ZNSp90kx
4su9N6LjvT5dvZ57EfMAr8h794xf/hzxNZTwPKty1E8nCx+hQte8l428GB7kLh5M
9KYLgygIFDDIdUNrZ8m3nwAIgijvkQwnuLVjgMjd1u6mTyt4IEx0GoLtmlBLIMPY
q/4Bue7Gw3wzpYTdMmPtlZCJjp9erIA1WVBrYkdUhHbmDfaKlcXr75Bnp4QulNF9
r2DQ+4XiFVFy91dEQUeBneMCAwEAAQ==
-----END PUBLIC KEY-----`;

export function setToken(token, refreshToken) {
	localStorage.setItem('jwt-token', token);
	localStorage.setItem('jwt-token-refresh', refreshToken);
}

export function getToken() {
	return localStorage.getItem('jwt-token');
}

export function getRefreshToken() {
	return localStorage.getItem('jwt-token-refresh');
}

export function isTokenValid() {
	try {
		jsonwebtoken.verify(getToken(), jwtPublicKey);
	} catch (e) {
		return false;
	}
	return true;
}

export function getTokenData() {
	const data = jsonwebtoken.decode(getToken());

	if (data === null) {
		return null;
	} else {
		return data;
	}
}

export function getUserRoles() {
	const data = getTokenData();
	if (data === null) {
		return null;
	} else {
		return data.roles;
	}
}

export function hasRole(role) {
	const roles = getUserRoles();
	if (roles !== null && Array.isArray(roles)) {
		return roles.includes(role);
	}
	return false;
}

export function refresh() {
	axios
		.post(appConfig.envVars.baseApiUrl + 'auth/refresh', {
			refresh_token: getRefreshToken(),
		})
		.then((tokenRefreshResponse) => {
			setToken(tokenRefreshResponse.data.token, tokenRefreshResponse.data.refresh_token);
			document.location.reload();
		});
}

export function getUserLogin() {
	const data = getTokenData();
	if (data === null) {
		return null;
	} else {
		return data.username;
	}
}

export function isAuthenticated() {
	return isTokenValid();
}

export function getUserId() {
	const data = getTokenData();
	if (data === null) {
		return null;
	} else if (data.user_id) {
		return data.user_id;
	}
}

export function getAccountTypeId() {
	const data = getTokenData();
	if (data.player_id !== null) {
		return data.player_id;
	}
	if (data.partner_id !== null) {
		return data.partner_id;
	}
	if (data.employee_id !== null) {
		return data.employee_id;
	}
}

export function logout() {
	localStorage.removeItem('jwt-token');
	localStorage.removeItem('jwt-token-refresh');
	window.location.replace('/login');
}

export function isPlayer() {
	const data = getTokenData();
	return data?.player_id !== null && hasRole('ROLE_USER_PLAYER_FRIEND_INVITATION_RECEIVED_GET_LIST_OWN');
}

export function isVPlayer() {
	const data = getTokenData();
	return data?.player_id !== null && !hasRole('ROLE_USER_PLAYER_FRIEND_INVITATION_RECEIVED_GET_LIST_OWN');
}

export function isPartner() {
	const data = getTokenData();
	return data?.partner_id !== null;
}

export function isEmployee() {
	const data = getTokenData();
	return data?.employee_id !== null;
}

export function getPlayerId() {
	const data = getTokenData();
	if (data.player_id) {
		return data.player_id;
	}
	if (data.partner_id) {
		return data.partner_id;
	}
	return null;
}

export const minPasswordLength = 8;

export const passwordLengthValidator = {
	validator(rule, value) {
		if (value.length < minPasswordLength) {
			return Promise.reject('Password has to contain at least ' + minPasswordLength + ' characters');
		}
		return Promise.resolve();
	},
};
