import axios from '../../axios-api';
import {Country, DictionaryItem} from '../Entities/types';

export const getNameByValue = (value: number | undefined, dictionary: DictionaryItem[]): string => {
	return (
		dictionary.find((item) => {
			return item.value === value;
		})?.name || ''
	);
};

export const getValueByName = (name: string, dictionary: DictionaryItem[]): number | undefined => {
	return (
		dictionary.find((item) => {
			return item.name === name;
		})?.value || undefined
	);
}

export const getDefaultValue = (dictionary: DictionaryItem[]): string => {
	return (
		dictionary.find((item) => {
			return item.is_default;
		})?.name || dictionary[2].name
	);
}

export const getCountryDicItemById = (id: number | undefined, countryDic: Country[]): Country | undefined => {
	return (
		countryDic.find((item) => {
			return item.id === id;
		}) || undefined
	);
}

export const getDictionary = async (url: string) => {
	try {
		const response = await axios.get(url);
		let newRes: DictionaryItem[] = [];

		response.data.forEach((el) => {
			newRes.push({
				name: el.value,
				value: el.id,
				is_default: el.is_default
			});
		});

		return newRes;
	} catch (e: any) {
		console.log(e);
	}
};
