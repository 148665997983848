import React, {FC} from 'react';
import styles from './UserHeaderSection.module.css';

type UserHeaderSectionProps = {};
const UserHeaderSection: FC<UserHeaderSectionProps> = ({children}) => {
	return (
		<section className={styles.Background}>
			<div className={styles.Container}>{children}</div>
		</section>
	);
};

export default UserHeaderSection;
