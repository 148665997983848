import React, {FC} from 'react';
import styles from './FooterBlock.module.css';
import Button from '../../Shared/Button/Button';

type FooterBlockProps = {};
const FooterBlock: FC<FooterBlockProps> = () => {
	return (
		<div className={styles.Container}>
			<img className={styles.Player} src={'/design/player_bottom.png'} alt={'Play esport'} />
			<div className={styles.InfoContainer}>
				<img
					className={styles.TennisBallLogo}
					src={'/design/logo_tennisball_2.png'}
					alt={'ESport tennisball logo'}
				/>
				<img className={styles.EsportLogo} src={'/design/logo_esports_2.png'} alt={'ESport tennisball logo'} />
				<p className={styles.Header}>THE FUTURE OF TENNIS BUSINESS IS HYBRID</p>
				<p className={styles.Text}>
					There is opportunity to expand tennis’ presence across cities during tennis fever, through virtual
					marketing, management and advertisement, showcasing immersive experiences of any environment. Get in
					touch to find out how your business can get involved
				</p>
				<Button href={'https://tennis-esports.com/contacts'}>Find out more</Button>
			</div>
		</div>
	);
};

export default FooterBlock;
