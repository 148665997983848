import axios from '../../axios-api';
import {getAccountTypeId} from '../../utils/security';
import {
	Player,
	MyRacket,
	Rating,
	PlayerStaff,
	SocialLink,
	Federation,
	Employee,
	PublicProfileSettings,
	PublicProfileCompetition,
	PublicProfileBasicData,
	StatisticsSummary,
	PublicPlayer,
} from '../../common/Entities/types';

const loadPlayerData = () => {
	return axios.get<Player>(`/user/player/${getAccountTypeId()}`).then((res) => res.data);
};

const loadPlayerDataPublic = (userId: string) => {
	return axios.get<PublicPlayer>(`/user/public/profile/basic-data/${userId}`).then((res) => res.data);
};

const loadCompetitionsDataPublic = (userId: string) => {
	return axios.get<PublicProfileCompetition>(`/user/public/profile/competitions-data/${userId}`).then((res) => res.data);
};

const loadEmployeeData = () => {
	return axios.get<Employee>(`/user/employee/${getAccountTypeId()}`).then((res) => res.data);
};

const loadPlayerGlobalScore = () => {
	return axios.get<Rating>(`/user/player/${getAccountTypeId()}/statistic/global-score`).then((res) => res.data);
};

const loadPlayerStatisticSummary = () => {
	return axios
		.get<StatisticsSummary>(`/user/player/${getAccountTypeId()}/statistic/summary?period_id=4`)
		.then((res) => res.data);
};

const loadMyRackets = () => {
	return axios.get<{data: MyRacket[]}>(`/racket`).then((res) => res.data);
};

const loadMySocialLink = () => {
	return axios.get<{data: SocialLink[]}>(`/social-link`).then((res) => res.data);
};

const loadMyStaff = () => {
	return axios.get<{data: PlayerStaff[]}>(`/player-staff`).then((res) => res.data);
};

const loadMyFederation = () => {
	return axios.get<{data: Federation[]}>(`/federation`).then((res) => res.data);
};

const loadPublicProfileSettings = () => {
	return axios.get<PublicProfileSettings>(`/user/public/profile`).then((res) => res.data);
};

const loadPublicPageSettings = (token: string) => {
	return axios.get<PublicProfileSettings>(`/user/public/profile/settings/${token}`).then((res) => res.data);
};

const loadPublicPageBasicData = (token: string) => {
	return axios.get<PublicProfileBasicData>(`/user/public/profile/basic-data/${token}`).then((res) => res.data);
};

const loadPublicPageSocialMediaData = (token: string) => {
	return axios.get<{data: SocialLink[]}>(`/user/public/profile/social-media-data/${token}`).then((res) => res.data);
};

const loadPublicPageCompetitionsData = (token: string) => {
	return axios
		.get<PublicProfileCompetition>(`/user/public/profile/competitions-data/${token}`)
		.then((res) => res.data);
};

export const requests = {
	loadPlayerData,
	loadEmployeeData,
	loadPlayerGlobalScore,
	loadMyRackets,
	loadMySocialLink,
	loadMyStaff,
	loadMyFederation,
	loadPublicProfileSettings,
	loadPublicPageSettings,
	loadPublicPageBasicData,
	loadPublicPageSocialMediaData,
	loadPublicPageCompetitionsData,
	loadPlayerStatisticSummary,
	loadPlayerDataPublic,
	loadCompetitionsDataPublic,
};
