import React, {FC, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {Store, ValidateErrorEntity} from 'rc-field-form/lib/interface';
import axios from '../../../axios-api';
import {Button, Form, Input, message} from 'antd';
import {passwordLengthValidator} from '../../../utils/security';
import styles from './ForgotPasswordForm.module.css';
import {AxiosError} from 'axios';

type ForgotPasswordFormProps = RouteComponentProps & {
	login: string;
	code: string;
};

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({history, login, code}: ForgotPasswordFormProps) => {
	const [loading, setLoading] = useState<boolean>(false);

	const onFinish = (values: Store) => {
		setLoading(true);
		axios
			.patch(`/auth/forgot-password/${code}`, {
				email: values.email,
				password: values.password,
				re_password: values.password,
			})
			.then((response) => {
				history.replace('/login');
				setLoading(false);
			})
			.catch((error: AxiosError) => {
				if (error.response) {
					message.error(error.response?.data.message);
				} else {
					message.error(error.message);
				}
				setLoading(false);
			});
	};

	const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<div className={styles.Container}>
			<p className={styles.Text}>Please specify your new password</p>
			<Form
				className="esp-register-form"
				name="restore-password"
				initialValues={{invitationCode: code ? code : null}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item
					className="esp-register-input"
					name="password"
					rules={[
						{required: true, message: 'Please input your password!'},
						passwordLengthValidator,
						() => ({
							validator(rule, value) {
								if (/^[A-Za-z0-9]+$/.test(value)) {
									return Promise.resolve();
								}
								return Promise.reject('Password must contains only letters and digits');
							},
						}),
					]}
				>
					<Input.Password placeholder={'Password'} />
				</Form.Item>

				<Form.Item
					className="esp-register-input"
					name="confirm"
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
							message: 'Please confirm your password!',
						},
						({getFieldValue}) => ({
							validator(rule, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject('The two passwords that you entered do not match!');
							},
						}),
					]}
				>
					<Input.Password placeholder={'Confirm password'} />
				</Form.Item>

				<div className={styles.ButtonsWrapper}>
					<Form.Item className="esp-register-button">
						<Button
							className="es-link-btn-no-auth es-link-btn-large width-100-mobile"
							type="primary"
							htmlType="submit"
							loading={loading}
						>
							Save
						</Button>
					</Form.Item>
				</div>
			</Form>
		</div>
	);
};

export default ForgotPasswordForm;
