import axios, {AxiosError} from 'axios';
import appConfig from './config';
import {getToken, setToken} from './utils/security';

const instance = axios.create({
	baseURL: appConfig.envVars.baseApiUrl,
	headers: {
		common: {
			Accept: 'application/json',
		},
	},
});

const publicUrls = [
	'^/auth$',
	'^/user/(player|partner)/register$',
	'^/user/player/register/playfab$',
	'^/user/player/statistic/global-score$',
	'^/user/statistic/count-per-country$',
	'^/system/contact-us/not-registered$',
	'^/payment/process/challenge-result$',
	'^/payment/card-authorization/challenge-result$',
	'^/dictionary/(.*)$',
	'^/dictionary/setting/([a-z0-9_]+)/value$',
	'^/game/current$',
	'^/tournament$',
	'^/tournament/([0-9]+)$',
	'^/tournament/([0-9]+)/round$',
	'^/tournament/round/([0-9]+)$',
	'^/tournament/v2/rating/manual$',
	'^/_profiler$',
	'/public$',
	'^/metrics/prometheus$',
	'^/feed/list$',
	'^/user/public/profile/settings/(.*)$',
	'^/user/public/profile/basic-data/(.*)$',
	'^/user/public/profile/equipment-data/racket/(.*)$',
	'^/user/public/profile/equipment-data/player-staff/(.*)$',
	'^/user/public/profile/social-media-data/(.*)$',
	'^/user/public/profile/competitions-data/(.*)$',
	'^/tournament/v2/leaderboard/playfab$',
];

instance.interceptors.request.use((config) => {
	let publicAccess = false;
	for (const pattern of publicUrls) {
		if (config.url?.search(pattern) !== -1) {
			publicAccess = true
			break;
		}
	}

	if (!publicAccess) {
		let token = getToken();
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error: AxiosError) => {
		if (
			error.response?.status === 401 &&
			error.config.url !== '/user/password' &&
			error.config.method !== 'patch'
		) {
			const token = getToken();
			if (token !== '') {
				setToken('', '');
				window.location.replace('/login');
			}
		}

		return Promise.reject(error);
	},
);

export default instance;
