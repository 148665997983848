import React from 'react';
import {Route} from 'react-router-dom';
import {RouteProps, Redirect} from 'react-router';
import {getUserRoles} from '../../utils/security';

//import BuyPage from '../Esport/Pages/BuyPage/BuyPage';
//import MyPage from '../Esport/Pages/MyPage/MyPage';
//import LoginPage from '../Esport/Pages/LoginPage/LoginPage';
//import RegisterPage from '../Esport/Pages/RegisterPage/RegisterPage';
//import VerifyPage from '../Esport/Pages/VerifyPage/VerifyPage';
//import ReferralPage from '../Esport/Pages/ReferralPage/ReferralPage';
//import PaymentEdit from '../Esport/Pages/MyPage/Tabs/Payments/PaymentEdit';
//import ProductInstanceEdit from '../Esport/Pages/MyPage/Tabs/ProductInstances/ProductInstanceEdit';
//import PlayerPage from '../Esport/Pages/UserPages/PlayerPage';
//import PartnerPage from '../Esport/Pages/UserPages/PartnerPage';
//import EmployeePage from '../Esport/Pages/UserPages/EmployeePage';
//import ForgotPasswordPage from '../Esport/Pages/ForgotPasswordPage/ForgotPasswordPage';
//import RatingsPage from '../Esport/Pages/RatingsPage/RatingsPage';
//import TournamentsPage from '../Esport/Pages/TournamentsPage/TournamentsPage';
//import PaymentProcessPage from '../Esport/Pages/PaymentProcessPage/PaymentProcessPage';

import MyPageV2 from '../../designV2/Pages/MyPage/MyPage';
import LoginPage from '../../designV2/Pages/LoginPage/LoginPage';
import RegisterPage from '../../designV2/Pages/RegisterPage/RegisterPage';
import ConfirmEmailPage from '../../designV2/Pages/ConfirmEmailPage/ConfirmEmailPage';
import ForgotPasswordPage from '../../designV2/Pages/ForgotPasswordPage/ForgotPasswordPage';
import PublicProfile from '../../designV2/Pages/PublicProfile/PublicProfile';

export interface ProtectedRouteProps {
	key: string;
	path: string[] | string;
	renderedComponent: string;
	needRoles?: string[];
}

const componentList: any = {
	LoginPage,
	RegisterPage,
	MyPageV2,
	ConfirmEmailPage,
	ForgotPasswordPage,
	PublicProfile,
};

export const ProtectedRoute = (props: ProtectedRouteProps & RouteProps) => {
	const beforeURL = window.location.pathname + window.location.search;
	return (
		<Route
			{...props}
			render={(routeProps) => {
				const userRoles = getUserRoles();
				const isAuthenticated = userRoles !== null;
				const Component = componentList[props.renderedComponent];

				if (
					!props.needRoles ||
					props.needRoles.length === 0 ||
					(isAuthenticated && props.needRoles.some((role) => userRoles.includes(role)))
				) {
					return <Component {...routeProps} />;
				}
				const location = {
					pathname: '/login',
					state: {from: {pathname: beforeURL}},
				};
				return <Redirect to={location} />;
			}}
			exact
		/>
	);
};
