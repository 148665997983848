import React, {FC, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {Store, ValidateErrorEntity} from 'rc-field-form/lib/interface';
import axios from '../../../axios-api';
import {Button, Form, Input, message} from 'antd';
import {passwordLengthValidator} from '../../../utils/security';
import styles from './RegistrationForm.module.css';

type RegisterFormProps = RouteComponentProps & {
	code?: string;
};

const RegistrationForm: FC<RegisterFormProps> = ({history, code}: RegisterFormProps) => {
	const [registering, setRegistering] = useState<boolean>(false);

	const onFinish = (values: Store) => {
		setRegistering(true);
		axios
			.post('/user/player/register', {
				nickname: values.nickname,
				email: values.email,
				password: values.password,
				re_password: values.password,
				referral: values.invitationCode ? values.invitationCode : code,
			})
			.then((response) => {
				history.replace('/register/confirm-email');
				setRegistering(false);
			})
			.catch((err) => {
				message.error(err.response?.data.message);
				setRegistering(false);
			});
	};

	const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Form
			className="esp-register-form"
			name="register"
			initialValues={{invitationCode: code ? code : null}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
		>
			<Form.Item
				className="esp-register-input"
				name="email"
				rules={[
					{
						required: true,
						message: 'Please input your email!',
					},
					{
						type: 'email',
						message: 'The input is not valid E-mail!',
					},
				]}
			>
				<Input placeholder={'Email'} />
			</Form.Item>

			<Form.Item
				className="esp-register-input"
				name="password"
				rules={[
					{required: true, message: 'Please input your password!'},
					passwordLengthValidator,
					() => ({
						validator(rule, value) {
							if (/^[A-Za-z0-9]+$/.test(value)) {
								return Promise.resolve();
							}
							return Promise.reject('Password must contains only letters and digits');
						},
					}),
				]}
			>
				<Input.Password placeholder={'Password'} />
			</Form.Item>

			<Form.Item
				className="esp-register-input"
				name="confirm"
				dependencies={['password']}
				hasFeedback
				rules={[
					{
						required: true,
						message: 'Please confirm your password!',
					},
					({getFieldValue}) => ({
						validator(rule, value) {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
							}
							return Promise.reject('The two passwords that you entered do not match!');
						},
					}),
				]}
			>
				<Input.Password placeholder={'Confirm password'} />
			</Form.Item>

			<div className="es-hr" />

			{/*
			<Form.Item className="esp-register-input" name="invitationCode">
				<Input placeholder={'Invitation code (optional)'} />
			</Form.Item>
			*/}

			<div className={styles.ButtonsWrapper}>
				<Form.Item className="esp-register-button">
					<Button
						className="es-link-btn-no-auth es-link-btn-large width-100-mobile"
						type="primary"
						htmlType="submit"
						loading={registering}
					>
						Register
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};

export default RegistrationForm;
