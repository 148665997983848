import React, {FC, useState, useMemo, useEffect} from 'react';
import {Store, ValidateErrorEntity} from 'rc-field-form/lib/interface';
import axios from '../../../axios-api';
import {useHistory} from "react-router-dom";
import {Button, DatePicker, Form, Input, message, Select} from 'antd';
import AvatarUpload from '../../../components/AvatarUpload/AvatarUpload';
import {
	Country,
	DictionaryItem,
	Player,
	PublicProfileBasicData,
	PublicProfileSettings,
	SocialLink
} from '../../../common/Entities/types';
import styles from './EditPlayerForm.module.css';
import {getPlayerId} from '../../../utils/security';
import {filterOption} from '../../../common/tools/select';
import moment from 'moment/moment';

import Flag from "../../Components/Flag/Flag";
import {homeAvatar, HomeAvatar} from "../../Pages/MyPage/consts";
import {getValueByName} from "../../../common/tools/dictionary";
import SocialMediaEditor from "../../Components/SocialMediaEditor/SocialMediaEditor";

export type EditPlayerFormProps = {
	player: Player | undefined;
	publicProfileSettings: PublicProfileSettings | undefined;
	currentSocialLinks: SocialLink[];
	homeAvatar: HomeAvatar;
	countryDic: Country[];
	handTypeDic: DictionaryItem[];
	playTypeDic: DictionaryItem[];
	bodyTypeDic: DictionaryItem[];
	timezoneDic: DictionaryItem[];
	homeAvatarDic: DictionaryItem[];
	socialMediaTypeDic: DictionaryItem[];
	onGenderChange: (value) => void;
};

const EditPlayerForm: FC<EditPlayerFormProps> = (props: EditPlayerFormProps) => {
	const [saving, setSaving] = useState<boolean>(false);
	const [currentCountry, setCurrentCountry] = useState<number>(props.player?.country_id ?? 1)
	const history = useHistory();

	const countryIso2 = useMemo(() => {
		return props.countryDic?.find(item => currentCountry === item.id)?.iso2;
	}, [currentCountry, props.countryDic])

	const onFinish = (values: Store) => {
		const homeAvatarId = getValueByName(props.homeAvatar, props.homeAvatarDic);

		const playerId = props.player?.id ? props.player?.id : getPlayerId();
		const playerData = {
			avatar: values.avatar ? values.avatar : null,
			first_name: values.first_name
				? values.first_name
				: props.player?.first_name
				? props.player?.first_name
				: null,
			last_name: values.last_name ? values.last_name : props.player?.last_name ? props.player?.last_name : null,
			nickname: values.nickname ? values.nickname : props.player?.nickname ? props.player?.nickname : null,
			timezone_id: values.timezone_id
				? values.timezone_id
				: props.player?.timezone_id
				? props.player?.timezone_id
				: null,
			country_id: values.country_id
				? values.country_id
				: props.player?.country_id
				? props.player?.country_id
				: null,
			body_type_id: values.body_type_id
				? values.body_type_id
				: props.player?.body_type_id
					? props.player?.body_type_id
					: null,
			hand_type_id: values.hand_type_id
				? values.hand_type_id
				: props.player?.hand_type_id
				? props.player?.hand_type_id
				: null,
			play_type_id: values.play_type_id
				? values.play_type_id
				: props.player?.play_type_id
				? props.player?.play_type_id
				: null,
			home_avatar_id: homeAvatarId ? homeAvatarId : null,
			birth_date: values.birth_date
				? moment(values.birth_date).format('YYYY-MM-DD')
				: props.player?.birth_date
				? props.player?.birth_date
				: null,
		};
		setSaving(true);
		axios
			.patch(`/user/player/${playerId}`, playerData)
			.then(() => {
				message.success('Successful updated');
				setSaving(false);
				history.push(`/my?tab=MyAccount&needRefresh=true`);
			})
			.catch((err) => {
				if (err.response?.status === 400 && err.response?.data?.property_path) {
					// todo backend errors
				}
				message.error(err.response?.data.message);
				setSaving(false);
			});
	};

	const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
		console.log('Failed:', errorInfo);
	};

	const onValuesChange = (changed: Store, allValues: Store) => {
		if (changed.country_id) {
			setCurrentCountry(changed.country_id);
		}
		if (changed.body_type_id) {
			props.onGenderChange(changed.body_type_id)
		}
	}

	return (
		<Form
			className="esp-register-form"
			name="edit-account"
			initialValues={{}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			onValuesChange={onValuesChange}
		>
			<div className={styles.FormContainer}>
				<div className={styles.FormPart}>
					<Form.Item name="avatar">
						<AvatarUpload defaultUrl={props.player?.avatar} />
					</Form.Item>

					<div className={styles.EditCountryContainer}>
						<div className={styles.FieldContainer}>
							<Form.Item
								name="country_id"
								label="Country"
								initialValue={props.player?.country_id}
								rules={[{required: true, message: 'Please fill this field'}]}
							>
								<Select showSearch loading={props.countryDic.length === 0} filterOption={filterOption}>
									{props.countryDic.map((i) => (
										<Select.Option value={i.id} key={i.id}>
											{i.value}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</div>
						<div className={styles.FlagContainer}>
							<Flag countryIso2={countryIso2} />
						</div>
					</div>

					<Form.Item
						name="nickname"
						label="Nick-name"
						initialValue={props.player?.nickname}
						rules={[
							{required: true, message: 'Please fill this field'},
							() => ({
								validator(rule, value) {
									if (/^[A-Za-z0-9_-]+$/.test(value)) {
										return Promise.resolve();
									}
									return Promise.reject(
										'Nick-name can contain only letters, digits and "-" and "_" symbols',
									);
								},
							}),
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="timezone_id"
						label="Timezone"
						initialValue={props.player?.timezone_id}
						rules={[{required: true, message: 'Please fill this field'}]}
					>
						<Select showSearch loading={props.timezoneDic.length === 0} filterOption={filterOption}>
							{props.timezoneDic.map((i) => (
								<Select.Option value={i.value} key={i.value}>
									{i.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						name="first_name"
						label="First Name"
						initialValue={props.player?.first_name}
						rules={[{required: true, message: 'Please fill this field'}]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="last_name"
						label="Last Name"
						initialValue={props.player?.last_name}
						rules={[{required: true, message: 'Please fill this field'}]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="birth_date"
						label="DoB / age"
						initialValue={
							props.player?.birth_date
								? moment(
									props.player ? props.player?.birth_date : moment().subtract(21, 'year'),
									'YYYY-MM-DD',
								)
								: undefined
						}
					>
						<DatePicker />
					</Form.Item>

					<Form.Item
						name="body_type_id"
						label="Gender"
						initialValue={props.player?.body_type_id}
					>
						<Select showSearch loading={props.bodyTypeDic.length === 0} filterOption={filterOption}>
							{props.bodyTypeDic.map((i) => (
								<Select.Option value={i.value} key={i.value}>
									{i.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						name="hand_type_id"
						label="Left / right hand"
						initialValue={props.player?.hand_type_id}
					>
						<Select showSearch loading={props.handTypeDic.length === 0} filterOption={filterOption}>
							{props.handTypeDic.map((i) => (
								<Select.Option value={i.value} key={i.value}>
									{i.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item name="play_type_id" label="Play type" initialValue={props.player?.play_type_id}>
						<Select showSearch loading={props.playTypeDic.length === 0} filterOption={filterOption}>
							{props.playTypeDic.map((i) => (
								<Select.Option value={i.value} key={i.value}>
									{i.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					{/*
					<SocialMediaEditor
						currentSocialLinks={props.currentSocialLinks}
						publicProfileSettings={props.publicProfileSettings}
						socialMediaTypeDic={props.socialMediaTypeDic}
					/>
					*/}
				</div>
			</div>

			<div className={styles.ButtonsWrapper}>
				<Form.Item className="esp-register-button">
					<Button
						className="es-link-btn-no-auth es-link-btn-large width-100-mobile"
						type="primary"
						htmlType="submit"
						loading={saving}
					>
						Save
					</Button>
				</Form.Item>
				<Form.Item className="esp-register-button">
					<Button
						className="es-link-btn-no-auth es-link-btn-large width-100-mobile"
						type="primary"
						onClick={() => history.go(-1)}
					>
						Close
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};

export default EditPlayerForm;
