import html2canvas from "html2canvas";
import FileSaver from "file-saver";

const exportAsImage = async (el: any, imageFileName: string) => {
  const canvas = await html2canvas(el, {backgroundColor:null});
  canvas.toBlob((blob: Blob | null) => {
    FileSaver.saveAs(blob as Blob, imageFileName);
  })
};

export default exportAsImage;