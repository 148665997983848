import stagingConfig from './stagingConfig';
import developmentConfig from './developmentConfig';
import prodConfig from './prodConfig';
import preprodConfig from './preprodConfig';
import routes from './routes';

let env = '__REACT_APP_ENV__';

const envConfigs: any = {
	prod: prodConfig,
	stg: stagingConfig,
	dev: developmentConfig,
	preprod: preprodConfig,
};

const config = {
	envVars: envConfigs[env],
	routes: routes,
};

if (config.envVars === undefined) {
	config.envVars = envConfigs['dev'];
}

export default config;
