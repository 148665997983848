import React, {FC} from 'react';
import {RouteComponentProps} from 'react-router';
import LoginForm from '../../Forms/LoginForm/LoginForm';
import UserHeaderSection from '../../Components/UserHeaderSection/UserHeaderSection';

import styles from './LoginPage.module.css';
import PageTitle from "../../Components/PageTitle/PageTitle";

type LoginPageProps = RouteComponentProps;

const LoginPage: FC<LoginPageProps> = (props: LoginPageProps) => {
	return (
		<UserHeaderSection>
			<PageTitle />
			<div className={styles.Container}>
				<LoginForm {...props} />
			</div>
		</UserHeaderSection>
	);
};

export default LoginPage;
