import {ReactElement} from 'react';

export const noop = () => {};

export const isReactElement = (value: any): value is ReactElement => !!value.$$typeof;

export interface PaginationModel {
	limit: number;
	offset: number;
}

export const toPaginationModel = (page: number, perPage: number): PaginationModel => ({
	offset: (page - 1) * perPage,
	limit: perPage,
});

export function roundObject<T>(obj: T): T {
	return Object.entries(obj).reduce((acc, [key, value]) => {
		acc[key] = Math.round(value);
		return acc;
	}, {} as T);
}