import React, {FC, useState} from 'react';
import {Button, Form, Input, message, Modal} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {Store} from 'rc-field-form/lib/interface';
import {AxiosError} from 'axios';
import {RouteComponentProps} from 'react-router';
import axios from '../../../axios-api';

type EmailVerificationProps = Pick<RouteComponentProps, 'history'>;

const EmailVerification: FC<EmailVerificationProps> = ({history}) => {
	const [form] = useForm();

	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);

	const onFinish = (value: Store) => {
		setLoading(true);
		try {
			axios
				.post(`/auth/resend-confirmation-email`, {email: value.email})
				.then((result) => {
					history.replace('/register/confirm-email');
					setShowModal(false);
				})
				.catch((error: AxiosError) => {
					if ((error.response?.status === 400 || error.response?.status === 404) && error.response?.data) {
						message.error(error.response?.data.message);
					} else {
						message.error(error.message);
					}
				});
		} catch (e: any) {
			setLoading(false);
			message.error(e.response?.data.message);
			console.log(e);
		}
	};

	return (
		<>
			<Button
				className="es-link-btn-no-auth es-link-btn-large width-100-mobile"
				type="primary"
				key="resend"
				onClick={() => setShowModal(true)}
			>
				Email verification
			</Button>
			<Modal
				destroyOnClose
				title="Email verification"
				visible={showModal}
				footer={false}
				onCancel={() => setShowModal(false)}
			>
				<p>Please specify your user Email address to receive confirmation link</p>
				<Form form={form} onFinish={onFinish}>
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								message: 'Please input email!',
							},
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
						]}
					>
						<Input placeholder={'Enter email'} />
					</Form.Item>

					<Button
						className={'es-link-btn-no-auth es-link-btn-large es-link-btn-center width-100-mobile'}
						type={'primary'}
						htmlType="submit"
						loading={loading}
					>
						Send
					</Button>
				</Form>
			</Modal>
		</>
	);
};

export default EmailVerification;
