import React, {useState, FC, useEffect} from 'react';
import {Avatar} from 'antd';
import {Link, NavLink} from 'react-router-dom';
import {Layout} from 'antd';
import Logo from '../Logo/Logo';
import styles from './EsportHeader.module.css';
import MainMenu from '../MainMenu/MainMenu';
import BuyVRTennis from '../../Esport/Components/BuyVRTennis/BuyVRTennis';
import {
	getAccountTypeId,
	getUserLogin,
	hasRole,
	isEmployee,
	isPartner,
	isPlayer,
	isVPlayer,
  isAuthenticated,
  logout,
} from '../../../utils/security';
import {userServices} from '../../../services/loadUserService';
import {Partner, Player, Employee, DisplayedUser} from '../../../common/Entities/types';
import {getMyAvatar} from '../../../utils/getAvatar/getAvatar';
import {getPublicPictureUrl} from '../../../common/tools/public_picture';
import config from '../../../config';
import notificationService from '../../../services/notificationService';

const {Header} = Layout;

type UserInfoProps = {
	user?: DisplayedUser;
};

const EsportHeader = () => {

  	const [menuOpened, setMenuOpened] = useState<boolean>(false);
	const [user, setUser] = useState<DisplayedUser>();
	const [loading, setLoading] = useState<boolean>(true);
	const [player, setPlayer] = useState<Player | undefined>(undefined);
	const [partner, setPartner] = useState<Partner | undefined>(undefined);
	const [employee, setEmployee] = useState<Employee | undefined>(undefined);
	const [, updateState] = React.useState(0);
	const forceUpdate = React.useCallback(() => updateState(Math.random()), []);

	useEffect(() => {
		notificationService.registerForceUpdate(forceUpdate);
	}, []);

	let notificationCount = notificationService.getNotificationCount();

	useEffect(() => {
		if (hasRole('ROLE_USER_PLAYER_GET_ONE') || hasRole('ROLE_USER_PARTNER_GET_ONE')) {
			loadPlayer();
		} else {
			setLoading(false);
		}
	}, [hasRole('ROLE_USER_PLAYER_GET_ONE') || hasRole('ROLE_USER_PARTNER_GET_ONE')]);

	const loadPlayer = () => {
		setLoading(true);
		if (isPlayer() || isVPlayer()) {
			userServices.loadUser('player').then((response) => {
				setPlayer(response);
				setUser({
					avatar: getMyAvatar(response.avatar, getPublicPictureUrl),
					displayName: response.nickname,
				});
				setLoading(false);
			});
		}

		if (isPartner()) {
			userServices.loadUser('partner').then((response) => {
				setPartner(response);
				setUser({
					avatar: getMyAvatar(response.clubs[0].logo, getPublicPictureUrl),
					displayName: response.clubs[0].name,
				});
				setLoading(false);
			});
		}

		if (isEmployee()) {
			userServices.loadUser('employee').then((response) => {
				setEmployee(response);
				setUser({
					avatar: response.avatar ? getPublicPictureUrl(response.avatar) : '/placeholder.png',
					displayName: getUserLogin(),
				});
				setLoading(false);
			});
		}
	};

	const exit = <span className={styles.Icon + " lnr lnr-exit"} onClick={() => logout()} />;

	const enter = (
		<NavLink to={'/my'} className={styles.EnterLink + " nodecor"}>
			<span className={styles.Icon + " lnr lnr-enter"} />
		</NavLink>
	);

	const myaccount = (
		<NavLink to={'/my?tab=MyAccount'} className={styles.AvatarLink + " nodecor"}>
      		{(isPlayer() || isVPlayer() || isPartner()) ? <Avatar className={styles.Avatar} size={80} src={getMyAvatar(user?.avatar ?? null)} /> : <span className={styles.Icon + " lnr lnr-user"} />}
			<div className={styles.AvatarInnerWrap}>
				{notificationCount ? (<div className={styles.AvatarBadge}>{notificationCount}</div>) : null}
			</div>
		</NavLink>
	);

  const myname = (
    <NavLink to={'/my?tab=MyAccount'} className={styles.Username}>{user?.displayName}</NavLink>
  );

	const getCMSHostName = () => {
		return config.envVars.cmsHost;
	};
	const cmsHostName = getCMSHostName();

  {/*console.log(user);*/}

  const siteName = 'TENNIS ESPORTS';

	return (
		<Header className={"header es-container"}>
			<div className={styles.HeaderContainer}>
				<Logo />
				<div className={styles.Middle}>
				<div className={styles.Slogan}>{siteName}</div>
				<div className={styles.Menu}>
					<MainMenu />
					<a href={cmsHostName} className={styles.Ep} key="EsportsProducts">Esport Product Site</a>
				</div>
				</div>

				{isAuthenticated() && myname}
				{isAuthenticated() ? myaccount : <BuyVRTennis />}
				<div className={styles.SloganMobile}>{siteName}</div>

				{isAuthenticated() ? exit : enter}
			</div>

			<div className="es-divider es-container es-mobile" />
			<div className={styles.MenuMobileContainer}>
				<div className={styles.Toggle} onClick={() => setMenuOpened(!menuOpened)}>
				<span className={styles.IconBar} />
				<span className={styles.IconBar} />
				<span className={styles.IconBar} />
				</div>
				<div className={styles.Menu + " " + styles.MenuMobile} style={{ display: (menuOpened ? "flex" : "none") }}>
				<MainMenu />
				<a href={cmsHostName} className={styles.Ep} key="EsportsProductsMobile">Esport Product Site</a>
				</div>
			</div>
		</Header>
	);
};

export default EsportHeader;
