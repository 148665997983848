import React, {FC} from 'react';
import styles from './CommonHeaderSection.module.css';

type CommonHeaderSectionProps = {};
const CommonHeaderSection: FC<CommonHeaderSectionProps> = ({children}) => {
	return (
		<section className={styles.Background}>
			<div className={styles.Container}>{children}</div>
		</section>
	);
};

export default CommonHeaderSection;
