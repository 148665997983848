import React, {FC} from 'react';
import styles from './Button.module.css';

type ButtonProps = {
	href?: string;
	onClick?: () => void;
};

const Button: FC<ButtonProps> = ({href, onClick, children}) => {
	if (onClick) {
		return (
			<a className={styles.Button} onClick={onClick} target={'_blank'}>
				{children}
			</a>
		);
	}

	return (
		<a className={styles.Button} href={href} target={'_blank'}>
			{children}
		</a>
	);
};

export default Button;
