import React, {FC, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {message, Result} from 'antd';
import axios from '../../../axios-api';
import {AxiosError} from 'axios';
import styles from './ForgotPasswordPage.module.css';
import CommonHeaderSection from '../../Components/CommonHeaderSection/CommonHeaderSection';
import HeaderBlock from '../../Components/HeaderBlock/HeaderBlock';
import Button from '../../Shared/Button/Button';
import CommonFooterSection from '../../Components/CommonFooterSection/CommonFooterSection';
import FooterBlock from '../../Components/FooterBlock/FooterBlock';
import UserHeaderSection from '../../Components/UserHeaderSection/UserHeaderSection';
import ForgotPasswordForm from '../../Forms/ForgotPasswordForm/ForgotPasswordForm';

type ForgotPasswordPageProps = RouteComponentProps<{
	code?: string;
}>;

const TokenIsNotValid = ({history}) => {
	return (
		<>
			<CommonHeaderSection>
				<HeaderBlock />
			</CommonHeaderSection>
			<div className={styles.ContentContainer}>
				<Result
					status="error"
					title="The password recovery link has expired"
					subTitle="Please submit new password recovery request"
					extra={[<Button onClick={() => history.push('/login')}>Ok</Button>]}
				/>
			</div>
			<CommonFooterSection>
				<FooterBlock />
			</CommonFooterSection>
		</>
	);
};

const ForgotPassword = ({props, login, code}) => {
	return (
		<UserHeaderSection>
			<div className={styles.HeaderContainer}>
				<ForgotPasswordForm {...props} login={login} code={code} />
			</div>
		</UserHeaderSection>
	);
};

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = (props: ForgotPasswordPageProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [login, setLogin] = useState<string>();
	const [isValid, setIsValid] = useState<boolean>(true);

	const {
		match: {
			params: {code},
		},
		history,
	} = props;

	const checkResetPasswordToken = (code: string) => {
		setLoading(true);
		axios
			.get(`/auth/forgot-password/${code}`)
			.then((result) => {
				setLogin(result.data.login);
				setIsValid(true);
				setLoading(false);
			})
			.catch((error: AxiosError) => {
				if (error.response) {
					message.error(error.response?.data.message);
				} else {
					message.error(error.message);
				}
				setIsValid(false);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (code) {
			checkResetPasswordToken(code);
		}
	}, [code]);

	return (
		<>
			{!isValid && <TokenIsNotValid history={history} />}
			{isValid && <ForgotPassword props={props} login={login} code={code} />}
		</>
	);
};

export default ForgotPasswordPage;
