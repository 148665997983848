import React, {FC, useEffect, useMemo, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {useHistory} from "react-router-dom";
import {Avatar} from 'antd';
import {QRCodeSVG} from "qrcode.react";

import {requests} from '../requests';
import {
	Country,
	DictionaryItem,
	Employee,
	Player,
	PublicProfileCompetition,
	PublicProfileBasicData,
	PublicProfileSettings,
	Rating,
	SocialLink,
	StatisticsSummary,
} from '../../../common/Entities/types';

import Loader from '../../../common/Components/Loader/Loader';
import Flag from "../../Components/Flag/Flag";
import SocialMedia from "../../Components/SocialMedia/SocialMedia";

import {getPublicPictureUrl} from '../../../common/tools/public_picture';
import {roundObject} from '../../../utils/helpers';
import {getQueryVariable} from '../../../common/tools/query';
import {isEmployee, isPlayer} from '../../../utils/security';
import {getMyAvatar} from '../../../utils/getAvatar/getAvatar';

import MyAccount from './Tabs/MyAccount';
import Settings from './Tabs/Settings';

import {
	loadAllCountryData,
	loadHomeAvatar,
	loadBodyType,
	loadHandType,
	loadPlayType,
	loadTimezone,
	loadSocialMediaType,
} from '../../../components/Esport/Pages/MyPage/requests';

import {HomeAvatar, homeAvatar} from "./consts";
import config from "../../../config";

import styles from './MyPage.module.css';

import settingsIcon from './assets/settings.png';
import worldIcon from './assets/world.png';
import playerIcon from './assets/player.png';
import handIcon from './assets/hand.png';
import logoHitemall from './assets/hitemall.png';
import logoInstantcup from './assets/instantcup.png';
import logoTour from './assets/tour.png';
import {getDefaultValue} from "../../../common/tools/dictionary";

export enum LeaderboardTypeEnum {
	HITEMALL = 'Comp_HitEmAll_Easy_NoReset',
	INSTANTCUP = 'Match_InstantCup_Reset',
	TE = 'TE_League'
}

export type LeaderboardItem = {
	name: string;
	logo: string;
	color: React.CSSProperties;
}

export type Leaderboards = {
	[s in LeaderboardTypeEnum]: LeaderboardItem
}
export const LeaderboardType: Leaderboards = {
	[LeaderboardTypeEnum.HITEMALL]: {
		name: `Hit'em all`,
		logo: logoHitemall,
		color: {color: '#D900DD'},
	},
	[LeaderboardTypeEnum.INSTANTCUP]: {
		name: 'Instant Cup',
		logo: logoInstantcup,
		color: {color: '#00A9FF'},
	},
	[LeaderboardTypeEnum.TE]: {
		name: 'Tour',
		logo: logoTour,
		color: {color: '#BAD739'},
	},
}

type MyPageProps = RouteComponentProps<{}>;

export const chooseAvatar = (avatar: string | undefined, gender: string | undefined) => {
	if (avatar) {
		return avatar
	}
	if (gender) {
		return gender
	}

	return 'default';
}

const MyPage: FC<MyPageProps> = ({location}: MyPageProps) => {
	const [loading, setLoading] = useState<boolean>(true);
	const history = useHistory();

	const [currentContent, setCurrentContent] = useState<string>('');
	const [currentLeaderboardIndex, setCurrentLeaderboardIndex] = useState<number>(0);

	const [player, setPlayer] = useState<Player | undefined>(undefined);
	const [employee, setEmployee] = useState<Employee | undefined>(undefined);

	const [publicProfileSettings, setPublicProfileSettings] = useState<PublicProfileSettings>();
	const [publicProfileCompetitions, setPublicProfileCompetitions] = React.useState<PublicProfileCompetition | null>(null);
	const [publicProfileBasicData, setPublicProfileBasicData] = useState<PublicProfileBasicData | null>(null);

	const [rating, setRating] = useState<Rating | undefined>(undefined);
	const [socialLink, setSocialLink] = useState<SocialLink[]>([]);
	const [statisticsSummary, setStatisticsSummary] = useState<StatisticsSummary | undefined>(undefined);

	const [handTypeDic, setHandTypeDic] = useState<DictionaryItem[]>([]);
	const [playTypeDic, setPlayTypeDic] = useState<DictionaryItem[]>([]);
	const [bodyTypeDic, setBodyTypeDic] = useState<DictionaryItem[]>([]);
	const [countryDic, setCountryDic] = useState<Country[]>([]);
	const [timezoneDic, setTimezoneDic] = useState<DictionaryItem[]>([]);
	const [homeAvatarDic, setHomeAvatarDic] = useState<DictionaryItem[]>([]);
	const [socialMediaTypeDic, setSocialMediaTypeDic] = useState<DictionaryItem[]>([]);

	let needRefresh = getQueryVariable('needRefresh', location.search);

	const homeAvatarItem = useMemo(() => {
		return homeAvatar[chooseAvatar(publicProfileBasicData?.home_avatar, publicProfileBasicData?.gender) as HomeAvatar || HomeAvatar.default];
	}, [needRefresh, publicProfileBasicData])

	useEffect(() => {
		let tab = getQueryVariable('tab', location.search);
		if (tab === '') {
			tab = 'MyAccount';
		}

		setCurrentContent(tab);
	}, [location.search]);

	useEffect(() => {
		if (isPlayer()) {
			setLoading(true);
			Promise.all([
				requests.loadPlayerData().then((data) => setPlayer(data)),
				requests.loadPlayerGlobalScore().then((data) => setRating(roundObject(data))),
				requests.loadMySocialLink().then((data) => setSocialLink(data.data)),
				requests.loadPlayerStatisticSummary().then((data) => setStatisticsSummary(data)),
				loadAllCountryData().then((data) => setCountryDic(data)),
				loadHandType().then((data) => setHandTypeDic(data)),
				loadPlayType().then((data) => setPlayTypeDic(data)),
				loadTimezone().then((data) => setTimezoneDic(data)),
				loadBodyType().then((data) => setBodyTypeDic(data)),
				loadHomeAvatar().then((data) => setHomeAvatarDic(data)),
				loadSocialMediaType().then((data) => setSocialMediaTypeDic(data)),
				requests.loadPublicProfileSettings().then((data) => {
					setPublicProfileSettings(data);
					return Promise.all([
						requests.loadCompetitionsDataPublic(data.token || '').then((data) => setPublicProfileCompetitions(data)),
						requests.loadPublicPageBasicData(data.token || '').then((data) => setPublicProfileBasicData(data)),
					]);
				}),
			])
				.then(([data]) => {
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		}
		if (isEmployee()) {
			setLoading(true);
			Promise.all([requests.loadEmployeeData().then((data) => setEmployee(data))])
				.then(() => {
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		}
	}, [needRefresh]);

	let content = (
		<div className={styles.LoaderContainer}>
			<Loader />
			<p className={styles.LoaderText}>You player profile data is being loaded...</p>
		</div>
	);

	const leaderboards = useMemo(() => Object.keys(publicProfileCompetitions?.leaderboards || {}).map(key => ({
		...publicProfileCompetitions?.leaderboards[key],
	})) || [], [publicProfileCompetitions]);

	useEffect(() => {
		if (leaderboards?.length) {
			setCurrentLeaderboardIndex(0);
		}
	}, [leaderboards]);

	const leaderboard = useMemo(() => leaderboards?.[currentLeaderboardIndex] || undefined, [leaderboards, currentLeaderboardIndex]);

	const goToPrev = () => {
		if (currentLeaderboardIndex > 0) {
			setCurrentLeaderboardIndex(currentLeaderboardIndex - 1);
		} else {
			setCurrentLeaderboardIndex(leaderboards.length - 1);
		}
	}

	const goToNext = () => {
		if (currentLeaderboardIndex < leaderboards.length - 1) {
			setCurrentLeaderboardIndex(currentLeaderboardIndex + 1);
		} else {
			setCurrentLeaderboardIndex(0);
		}
	}

	const countryIso2 = useMemo(() => {
		return countryDic?.find(item => player?.country_id === item.id)?.iso2;
	}, [player, countryDic])

	if (!loading && (player || employee)) {
		const goToSettings = () => {
			setCurrentContent('Settings');
			history.push(`/my?tab=Settings`);
		}

		const renderMyAccountCardInfo = () => (
			<div className={styles.GradientCard + ' ' + styles.MyAccountCard}>
				<div className={styles.BigFlagWrapper}>
					<Flag countryIso2={countryIso2} useAsBg={true} />
				</div>
				{player?.nickname && <div className={styles.UserNameWrapper}>
					<h3 className={styles.UserName}>
						{player?.nickname}
					</h3>
				</div>}
				<div className={styles.Data}>
					<div className={styles.MainInfoWrapper}>
						<div className={styles.MainInfoColumn}>
							<img alt={'homeAvatarItem'} src={homeAvatarItem} className={styles.PlayerImage} />
							<QRCodeSVG className={styles.QRCodeImage} value={`${config.envVars.baseHost}/public-profile/${publicProfileSettings?.token}`} includeMargin={true} size={window.innerWidth > 1200 ? 140 : 100} />
						</div>
						<div className={styles.MainInfoColumn}>
							<div className={styles.AvatarWrapper}>
								<Avatar
									className={styles.MainInfoAvatar}
									size={window.innerWidth > 1200 ? 140 : 100}
									src={getMyAvatar(player?.avatar, getPublicPictureUrl)}
								/>
							</div>
							<div className={styles.UserInfoItem}>
								<img className={styles.UserInfoLabel} src={handIcon} alt={'hand type'} />
								<p className={styles.UserInfoValue}>{publicProfileBasicData?.hand_type ?? getDefaultValue(handTypeDic)}</p>
							</div>
							<div className={styles.UserInfoItem}>
								<img className={styles.UserInfoLabel} src={playerIcon} alt={'play type'} />
								<p className={styles.UserInfoValue}>{publicProfileBasicData?.play_type ?? getDefaultValue(playTypeDic)}</p>
							</div>
							<div className={styles.UserInfoItem}>
								<img className={styles.UserInfoLabel} src={worldIcon} alt={'timezone'} />
								<p className={styles.UserInfoValue}>{publicProfileBasicData?.time_zone}</p>
							</div>
							<div className={styles.SocialMediaWrapper}>
								<SocialMedia
									currentLinks={socialLink}
									publicProfileSettings={publicProfileSettings}
								/>
							</div>
							<a href={`${config.envVars.baseHost}/public-profile/${publicProfileSettings?.token}`} target={"_blank"}><h3 className={styles.PlayerCardLink}>PLAYER CARD</h3></a>
							<div className={styles.SettingsItem} onClick={goToSettings}>
								<img src={settingsIcon} alt={'settings'} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)

		const renderRating = () => (
			<div className={styles.GradientCard}>
				<h3 className={styles.RatingTitle}>TENNIS ESPORTS RATINGS</h3>
				<div className={styles.RatingsRow}>
					<div className={styles.RatingsCell}>
						<div className={styles.RatingItem}>
							<div className={styles.RatingLabel}>RATING</div>
							<div className={styles.RatingValueBold}>{rating?.rating}</div>
						</div>
						<div className={styles.RatingItem}>
							<div className={styles.RatingLabel}>MATCHES</div>
							<div className={styles.RatingValue}>{statisticsSummary?.match_count}</div>
						</div>
					</div>
					<div className={styles.RatingsCell}>
						<div className={styles.RatingItem}>
							<div className={styles.RatingLabel}>RANK</div>
							<div className={styles.RatingValueBold}>{rating?.rank}</div>
						</div>
						<div className={styles.RatingItem}>
							<div className={styles.RatingLabel}>WIN / LOSS</div>
							<div className={styles.RatingValue}>
								{Math.round(
									((statisticsSummary?.match_won_count || 0) * 100) / (statisticsSummary?.match_count || 1)
								)}%</div>
						</div>
					</div>
				</div>
			</div>
		)

		const renderLeaderboard = () => {
			return leaderboard && leaderboard.type && (
				<div className={styles.GradientCard + ' ' + styles.LeaderboardCard}>
					<h3 className={styles.RatingTitle}>LEADERBOARDS</h3>
					<h3 className={styles.LeaderboardName} style={LeaderboardType[leaderboard.type].color}>{LeaderboardType[leaderboard.type].name}</h3>
					<div className={styles.ArrowLeft} onClick={goToPrev}>&lt;</div>
					<div className={styles.ArrowRight} onClick={goToNext}>&gt;</div>
					<div className={styles.RatingsRow + ' ' + styles.mb25}>
						<div className={styles.RatingsCell}>
							<img className={styles.RatingImg} src={LeaderboardType[leaderboard.type].logo} alt={'logo'} />
							<div className={styles.RatingItem}>
								<div className={styles.RatingLabel}>MATCHES</div>
								<div className={styles.RatingValue}>{leaderboard.matches_played ?? 0}</div>
							</div>
						</div>
						<div className={styles.RatingsCell}>
							<div className={styles.RatingItem}>
								<div className={styles.RatingLabel}>RANK</div>
								<div className={styles.RatingValueBold}>{leaderboard.rank}</div>
							</div>
							<div className={styles.RatingItem}>
								<div className={styles.RatingLabel}>POINTS</div>
								<div className={styles.RatingValue}>{new Intl.NumberFormat('en-US').format(leaderboard.points ?? 0)}</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		content = (
			<>
				{currentContent === 'MyAccount' && (
					<div className={styles.PlayerCardsContainer}>
						<div className={styles.PlayerCardsWrapper}>
							{renderMyAccountCardInfo()}
						</div>
						<div className={styles.PlayerCardsWrapper}>
							{renderRating()}
							{renderLeaderboard()}
						</div>
					</div>
				)}

				{player && currentContent === 'Settings' && (
					<Settings
						player={player}
						countryDic={countryDic}
						timezoneDic={timezoneDic}
						handTypeDic={handTypeDic}
						playTypeDic={playTypeDic}
						bodyTypeDic={bodyTypeDic}
						homeAvatarDic={homeAvatarDic}
						socialMediaTypeDic={socialMediaTypeDic}
						basicProfileData={publicProfileBasicData}
						publicProfileSettings={publicProfileSettings}
						currentSocialLinks={socialLink}
					/>
				)}
			</>
		);
	}

	return (<div className={styles.MainBg}>
		<div className={styles.MainContainer}>{content}</div>
	</div>);
};

export default MyPage;
