import React, {FC, useEffect, useState} from 'react';
import {message, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import styles from './AvatarUpload.module.css';
import {RcFile, UploadChangeParam} from 'antd/es/upload';
import appConfig from '../../config';
import {getPublicPictureUrl} from '../../common/tools/public_picture';

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};

interface AvatarUploadProps {
	defaultUrl?: string;
	onChange?: (value: string) => void;
	center?: boolean;
}

const AvatarUpload: FC<AvatarUploadProps> = ({defaultUrl, onChange, center}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [img, setImg] = useState<string>();

	useEffect(() => {
		if (defaultUrl) {
			setImg(getPublicPictureUrl(defaultUrl));
		}
	}, [defaultUrl]);

	const beforeUpload = (file: RcFile) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');
		}
		if (isJpgOrPng && isLt2M) {
			getBase64(file, (imageB64) => {
				setLoading(false);
				setImg(imageB64);
				if (onChange) {
					onChange(imageB64);
				}
			});
		}

		setLoading(false);
		return false;
	};

	const handleChange = (info: UploadChangeParam) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
	};

	return (
		<ImgCrop rotate>
			<Upload
				name="avatar"
				listType="picture-card"
				className={styles.AvatarUpload + (center ? ' ' + styles.AvatarUploadCenter : '')}
				showUploadList={false}
				style={{ borderRadius: 50 }}
				beforeUpload={beforeUpload}
				onChange={handleChange}
			>
				{img ? (
					<img src={img} alt={'user_avatar'} className={styles.AvatarImg} />
				) : (
					<div>
						{loading ? <LoadingOutlined /> : <PlusOutlined />}
						<div className="ant-upload-text">Upload</div>
					</div>
				)}
			</Upload>
		</ImgCrop>
	);
};

export default AvatarUpload;
