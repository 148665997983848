import React, {FC, useState, useEffect, useMemo, useRef} from 'react';
import {RouteComponentProps} from 'react-router';
import styles from './PublicProfile.module.css';
import Loader from '../../../common/Components/Loader/Loader';
import {requests} from '../requests';
import {
	Country,
	PublicProfileBasicData,
	PublicProfileCompetition,
	PublicProfileSettings,
	SocialLink, DictionaryItem,
} from '../../../common/Entities/types';
import {Avatar} from 'antd';
import {getMyAvatar} from '../../../utils/getAvatar/getAvatar';
import {getPublicPictureUrl} from '../../../common/tools/public_picture';
import {loadAllCountryData, loadHandType, loadPlayType} from '../../../components/Esport/Pages/MyPage/requests';
import {QRCodeSVG} from 'qrcode.react';
import Flag from "../../Components/Flag/Flag";
import {HomeAvatar, homeAvatar} from "../MyPage/consts";
import {chooseAvatar, LeaderboardType} from "../MyPage/MyPage";
import config from "../../../config";
import Button from "../../Shared/Button/Button";
import exportAsImage from "../../../utils/exportImage";

import worldIcon from '../MyPage/assets/world.png';
import playerIcon from '../MyPage/assets/player.png';
import handIcon from '../MyPage/assets/hand.png';
import tesLogo from './assets/tes-logo.png';
import {getDefaultValue} from "../../../common/tools/dictionary";

type PublicProfileProps = RouteComponentProps<{
	token?: string;
}>;

const PublicProfile: FC<PublicProfileProps> = (props: PublicProfileProps) => {
	const {
		match: {
			params: {token},
		},
	} = props;

	const exportRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const [loading, setLoading] = useState<boolean>(true);
	const [publicProfileSettings, setPublicProfileSettings] = useState<PublicProfileSettings>();
	const [basicData, setBasicData] = useState<PublicProfileBasicData>();
	const [socialLinkData, setSocialLinkData] = useState<SocialLink[]>([]);
	const [competitionsData, setCompetitionsData] = useState<PublicProfileCompetition>();

	const [handTypeDic, setHandTypeDic] = useState<DictionaryItem[]>([]);
	const [playTypeDic, setPlayTypeDic] = useState<DictionaryItem[]>([]);
	const [countryDic, setCountryDic] = useState<Country[]>([]);

	useEffect(() => {
		if (token) {
			setLoading(true);
			Promise.all([
				requests.loadPublicPageSettings(token).then((data) => setPublicProfileSettings(data)),
				requests.loadPublicPageBasicData(token).then((data) => setBasicData(data)),
				requests.loadPublicPageSocialMediaData(token).then((data) => setSocialLinkData(data.data)),
				requests.loadPublicPageCompetitionsData(token).then((data) => setCompetitionsData(data)),
				loadAllCountryData().then((data) => setCountryDic(data)),
				loadHandType().then((data) => setHandTypeDic(data)),
				loadPlayType().then((data) => setPlayTypeDic(data)),
			])
				.then(() => {
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		}
	}, []);

	const countryIso2 = useMemo(() => {
		return countryDic?.find(item => basicData?.country === item.id)?.iso2;
	}, [basicData, countryDic])

	const homeAvatarItem = useMemo(() => {
		return homeAvatar[chooseAvatar(basicData?.home_avatar, basicData?.gender) as HomeAvatar || HomeAvatar.default];
	}, [basicData])

	const captureImage = () => {
		exportAsImage(exportRef.current, "player_card")
	}

	let content = (
		<div className={styles.LoaderContainer}>
			<Loader />
		</div>
	);

	if (!loading) {
		content = (
			<div className={styles.GradientCard  + ' ' + styles.PublicProfileCard} ref={exportRef}>
				<div className={styles.BigFlagWrapper}>
					<Flag countryIso2={countryIso2} useAsBg={true} />
				</div>
				{basicData?.nickname && <div className={styles.UserNameWrapper}>
					<h3 className={styles.UserName}>
						{basicData?.nickname}
					</h3>
				</div>}
				<div className={styles.Container}>
					<div className={styles.MainSection}>
						<div className={styles.PlayerDataWrapper}>
							<div className={styles.HomeAvatarWrapper}>
								<img alt={'homeAvatarItem'} src={homeAvatarItem} className={styles.HomeAvatarItem} />
							</div>
							<div className={styles.MainInfo}>
								<div className={styles.AvatarWrapper}>
									<Avatar
										size={window.innerWidth > 1200 ? 140 : 100}
										src={getMyAvatar(basicData?.avatar, getPublicPictureUrl)}
									/>
								</div>
								<div className={styles.UserInfoItem}>
									<img className={styles.UserInfoLabel} src={handIcon} alt={'hand type'} />
									<p className={styles.UserInfoValue}>{basicData?.hand_type ?? getDefaultValue(handTypeDic)}</p>
								</div>
								<div className={styles.UserInfoItem}>
									<img className={styles.UserInfoLabel} src={playerIcon} alt={'play type'} />
									<p className={styles.UserInfoValue}>{basicData?.play_type ?? getDefaultValue(playTypeDic)}</p>
								</div>
								<div className={styles.UserInfoItem}>
									<img className={styles.UserInfoLabel} src={worldIcon} alt={'timezone'} />
									<p className={styles.UserInfoValue}>{basicData?.time_zone}</p>
								</div>
							</div>
						</div>
						<div className={styles.RatingInfo}>
							<div className={styles.RatingItem}>
								<p className={styles.RatingItemLabel}>RATING</p>
								<p className={styles.RatingItemValueBold}>{competitionsData?.te_rating}</p>
							</div>
							<div className={styles.RatingItem}>
								<p className={styles.RatingItemLabel}>RANK</p>
								<p className={styles.RatingItemValueBold}>{competitionsData?.te_rank}</p>
							</div>
							<div className={styles.RatingItem}>
								<p className={styles.RatingItemLabel}>MATCHES</p>
								<p className={styles.RatingItemValue}>{competitionsData?.matches_played}</p>
							</div>
							<div className={styles.RatingItem}>
								<p className={styles.RatingItemLabel}>WIN / LOSS</p>
								<p className={styles.RatingItemValue}>{competitionsData?.matches_won_percentage}%</p>
							</div>
						</div>
					</div>
					<div className={styles.LeaderboardSection}>
						{Object.entries(LeaderboardType).map(lb => (
							<div key={lb[0]} className={styles.LeaderboardCard}>
								<h3 className={styles.LeaderboardTitle} style={lb[1].color}>{lb[1].name}</h3>
								<img alt={'leaderboard logo'} className={styles.LeaderboardLogo} src={lb[1].logo} />
								<div className={styles.LeaderboardRatingWrapper}>
									<div className={styles.LeaderboardRatingItem}>
										<p className={styles.LeaderboardRatingItemLabel}>RANK</p>
										<p className={styles.LeaderboardRatingItemValueBold}>{competitionsData?.leaderboards[lb[0]].rank}</p>
									</div>
									<div className={styles.LeaderboardRatingItem}>
										<p className={styles.LeaderboardRatingItemLabel}>POINTS</p>
										<p className={styles.LeaderboardRatingItemValue}>{new Intl.NumberFormat('en-US').format(competitionsData?.leaderboards[lb[0]].points ?? 0)}</p>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className={styles.FooterSection}>
						<div className={styles.FooterItem}>
							<QRCodeSVG className={styles.QRCodeImage} value={`${config.envVars.baseHost}/public-profile/${publicProfileSettings?.token}`} includeMargin={true} size={window.innerWidth > 1200 ? 140 : 120} />
						</div>
						<div className={styles.FooterItem}>
							<img className={styles.TesLogo} src={tesLogo} alt={'tennis esport logo'} />
							<div className={styles.FooterSocialWrapper}>
								<a href="https://www.instagram.com/tennisesports" target={"_blank"} className={styles.FooterSocial}>
									<i className="fa-brands fa-instagram" />
								</a>
								<a href="https://www.youtube.com/channel/UCV13xYb3sNCZ5WUyNMHkX5g" target={"_blank"} className={styles.FooterSocial}>
									<i className="fa-brands fa-youtube" />
								</a>
								<a href="https://www.linkedin.com/groups/12327035/" target={"_blank"} className={styles.FooterSocial}>
									<i className="fa-brands fa-linkedin-in" />
								</a>
								<a href="https://www.facebook.com/vrmotionlearning" target={"_blank"} className={styles.FooterSocial}>
									<i className="fa-brands fa-facebook-f" />
								</a>
							</div>
							<a className={styles.FooterSocialLink} href={"https://www.tennis-esports.com"}>www.tennis-esports.comaa</a>
						</div>
						<div className={styles.FooterItem}>
							<Button onClick={captureImage}>Save card</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (<div className={styles.MainBg}>
		<div className={styles.MainContainer}>{content}</div>
	</div>);
};


export default PublicProfile;
