import React from 'react';
import {Layout} from 'antd';
import EsportHeader from './EsportHeader/EsportHeader';
import EsportFooter from './EsportFooter/EsportFooter';
import {isAuthenticated} from '../../utils/security';

const {Content} = Layout;

const EsportLayout = (props: any) => {
	return (
		<Layout className={'es-layout ' + (isAuthenticated() ? 'esp-user-logged-in' : 'esp-user-logged-out')}>
			<EsportHeader />
      <div className="es-divider es-container" />
			<Content className="content es-container">{props.children}</Content>
      <div className="es-divider es-container" />
			<EsportFooter />
		</Layout>
	);
};

export default EsportLayout;
