import React, {FC} from 'react';
import {RouteComponentProps} from 'react-router';
import {Result} from 'antd';
import EmailVerification from '../../Components/EmailVerification/EmailVerification';
import LoginForm from '../../Forms/LoginForm/LoginForm';
import UserHeaderSection from '../../Components/UserHeaderSection/UserHeaderSection';

import styles from './ConfirmEmailPage.module.css';
import RegistrationForm from "../../Forms/RegistrationForm/RegistrationForm";

type ConfirmEmailPageProps = RouteComponentProps<{
	token?: string;
}>;
const ConfirmEmailPage: FC<ConfirmEmailPageProps> = (props: ConfirmEmailPageProps) => {
	const {
		match: {
			params: {token},
		},
	} = props;

	let content = (
		<>
			<UserHeaderSection>
				<div className={styles.HeaderContainer}>
					<RegistrationForm {...props} />
				</div>
				<div className={styles.ContentContainer}>
				<Result
					status="success"
					title="You have successfully registered"
					subTitle="Please check your email for confirmation"
					extra={[<EmailVerification {...props} />]}
				/>
				</div>
			</UserHeaderSection>
		</>
	);

	if (token) {
		content = (
			<UserHeaderSection>
				<div className={styles.HeaderContainer}>
					<LoginForm {...props} token={token} />
				</div>
			</UserHeaderSection>
		);
	}

	return content;
};

export default ConfirmEmailPage;
