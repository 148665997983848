import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Esport from './components/Esport/Esport';
import EsportV2 from './designV2/Esport';
//import './App.less';
import './AppV2.less';
import {UserTimezone} from './utils/userTime';
import {isPlayer} from "./utils/security";

new UserTimezone().setAsMomentsDefault();

const App = () => {
	//const useV2 = isPlayer();
	const useV2 = true;

	//console.log("useV2?", useV2);

	if (useV2) {
		return (
			<BrowserRouter>
				<EsportV2 />
			</BrowserRouter>
		);
	} else {
		return (
			<BrowserRouter>
				<Esport />
			</BrowserRouter>
		);
	}
};

export default App;
